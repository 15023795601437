import React from 'react';
import { Body, Page } from '../LandingTemplate';
import { Helmet } from 'react-helmet';
import { useAnalytic } from '../../../constants/analytics/analytics';
import PageTitle from '../PageTitle';
import Loading from '../../Loading';
import styled from 'styled-components';

const FullPageBody = styled(Body)`
  height: 100vh;
`;

const LoadingPageTemplate: React.FunctionComponent<IProps> = ({
  centered = false,
  analyticTitle,
  title,
}) => {
  useAnalytic(analyticTitle);
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <PageTitle title={title} />
      <FullPageBody centered={centered}>
        <Loading />
      </FullPageBody>
    </Page>
  );
};

export default LoadingPageTemplate;

interface IProps {
  centered?: boolean;
  title: string;
  analyticTitle: string;
}
