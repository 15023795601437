import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import Button from '../components/Button';
import { RowAvatar } from 'components/RowAvatar';
import Toggle from 'react-toggle';
import { ReactComponent as MessageIcon } from 'assets/message_icon.svg';
import { ReactComponent as EditIcon } from '../assets/edit_icon.svg';
import { ReactComponent as RefreshIcon } from '../assets/refresh_button.svg';
import { ReactComponent as InfoIcon } from '../assets/info_icon.svg';
import { ReactComponent as AnalyticsIcon } from '../assets/patient_analytics_icon.svg';
import ReactTooltip from 'react-tooltip';
import Loading from '../components/Loading';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { BillStatus } from './ui';

export const theme = {
  colors: {
    aquamarine: '#4EF4FF',
    mercury: '#F1F4F6',
    picton: '#14B7EB',
    pictonAqua: '#4BD4E4',
    pictonBlue: '#3EA9F6',
    pictonGrey: '#57bbc8',
    pictonSky: '#73fbfc',
    pictonGreen: '#61d183',
    error: 'red',
    pictonDarkBlue: '#294fbf',
    dodger: '#247CFF',
    blue: 'rgba(36, 123, 255, 1)',
    regent: '#8293A4',
    regentLight: 'rgba(173, 177, 185, 0.7)',
    elephant: '#0E2941',
    white: '#FFFFFF',
    pictonBlueTransparent: 'rgba(62, 169, 246, 0.4)',
    dodgerTransparent: 'rgba(36, 124, 255, 0.4)',
    regentTransparent: 'rgba(130, 147, 164, 0.4)',
    geyser: '#DBE1E5',
    mercurysolid: '#E5E5E5',
    porcelain: '#f1f4f5',
    green: 'green',
    aquaBlueGradient: `linear-gradient(270deg, #4BD4E4 0%, #3EA9F6 100%)`,
  },
  fonts: {
    regular: `'Roboto Regular', sans-serif`,
    medium: `'Roboto Medium', sans-serif`,
    bold: `'Roboto Bold', sans-serif`,
    poppinsRegular: `'Poppins Regular', sans-serif`,
    poppinsBold: `'Poppins Bold', sans-serif`,
  },
};

export const globalStyles = `
html {
    * {
        box-sizing: border-box;
    }
}

body {
    background-color: #F1F4F6;
    min-height: 100vh;
    display: flex;
    &.ReactModal__Body--open {
        overflow: hidden;

        #root {
            overflow: hidden;
        }
    }

    & .ReactModal__Content {
        @media (max-width: 768px) {
            border-radius: 10px !important;
        }
    }
}

#root {
   @media (max-width: 768px) {
     -webkit-transform:translate3d(0,0,0);
   }
   flex-grow: 1;
   display: flex;
}
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(130, 147, 164, 0.4);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  overflow: hidden;
  flex-shrink: 0;
  & * {
    color: ${(props) => props.theme.colors.regent};
  }
`;

export const Col = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.colors.elephant};
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
`;

export const Delete = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  width: 30px;
  height: 30px;
  opacity: 0.4;
  margin: auto;
  display: none;

  &:hover {
    opacity: 1;
  }
`;

export const Row = styled.div`
  height: 87px;
  border-bottom: 1px solid rgba(130, 147, 164, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-evenly;

  &:hover {
    background-color: rgba(130, 147, 164, 0.05);
    border-bottom: 1px solid transparent;

    ${Delete} {
      display: block;
    }
  }
`;

export const ResendInvite: any = styled(Button)`
  max-width: 220px;
  color: #8293a4;
  border: 2px solid #8293a4;
  margin: auto;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const AvatarContainer = styled(RowAvatar)`
  margin: auto;
`;

export const ToggleRowWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ToggleTopMargin = styled.div`
  margin-bottom: 10px;
  margin-top: 7px;
`;

export const BottomNumberOfRowLeft = styled.div`
  width: 30%;
  margin: auto;

  @media (max-width: 400px) {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const BottomNumberOfRowRight = styled.div`
  width: 70%;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const RowBottomNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const StyledMessageIcon = styled(MessageIcon)`
  cursor: pointer;
  margin: auto;
  display: unset;

  width: 38px;
  height: 38px;
  padding: 6px;
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.pictonBlue};
    }
  }
`;

export const StyledAnalyticsIcon = styled(AnalyticsIcon)`
  cursor: pointer;
  margin: auto;
  display: unset;

  width: 38px;
  height: 38px;
  padding: 6px;
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.pictonBlue};
    }
  }
`;

export const StyledToggle = styled(Toggle)`
  &.react-toggle {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) => props.theme.colors.regent} !important;
      }
    }
    .react-toggle-track {
      background-color: ${(props) => props.theme.colors.regent};
    }
    .react-toggle-thumb {
      border-color: transparent;
    }
  }
  &.react-toggle--checked {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) =>
          props.theme.colors.pictonBlue} !important;
      }
    }
    .react-toggle-track {
      background-color: ${(props) => props.theme.colors.pictonBlue};
    }
  }
`;

export const PageContainer = styled.div`
  padding: 60px 60px 0 60px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 20px 0 20px;
    font-size: 12px;
  }
`;

export const AddButton = styled(Button)`
  background: ${(props) => props.theme.colors.pictonGrey};
  color: white;
  max-width: 220px;
  margin: ${(props) => (props.margin ? props.margin : "auto")};

  &:disabled {
    opacity: 0.6;
  }

  &:disabled {
    cursor: default;

    &:hover {
      background: ${(props) => props.theme.colors.pictonGrey};
    }
  }
`;

export const GenerateAnalyticsButton = AddButton

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TableBar = styled.div`
  margin: 24px 0 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OverflowWrapper = styled.div`
  overflow: auto;
`;

export const Cancel = styled(Button)`
  max-width: 220px;
  color: #8293a4;
  border: 2px solid ${(props) => props.theme.colors.pictonGrey};
  margin-right: 5px;

  &:hover:enabled {
    background: transparent;
    border-color: ${(props) => props.theme.colors.pictonGrey};
    color: ${(props) => props.theme.colors.pictonGrey};
  }
`;

export const DescriptionModal = styled.div`
  padding: 0 40px;
  margin: 40px 0 0 0;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: 1px solid rgba(130, 147, 164, 0.4);
`;

export const Inputs = styled.div`
  padding: 20px 40px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }
`;

export const Edit = styled(EditIcon)`
  cursor: pointer;
  width: 34px;
  height: 34px;
  padding: 4px 6px 6px 6px;
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.pictonBlue};
    }
  }
`;

export const DrawerItem = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 23px;
`;

export const Avatar: any = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 10px;
  background-color: ${(props: any) =>
    props.url ? 'transparent' : props.theme.colors.regent};
  background-image: url(${(props: any) => props.url});
  background-position: center;
  background-size: cover;

  @media (max-width: 400px) {
    display: none;
  }
`;

export const UnSetProperty = styled.span`
  color: ${(props) => props.theme.colors.regent};
  font-style: italic;
`;

export const BillingPastDueSpan = styled.span<{ billingPastDue: Number }>`
  color: ${(props) =>
    props.billingPastDue === BillStatus.BillSuccessAndNoTests
      ? props.theme.colors.regent
      : props.billingPastDue === BillStatus.BillSuccessAndTests
      ? props.theme.colors.pictonGreen
      : props.billingPastDue === BillStatus.NotBilledAndTests
      ? props.theme.colors.error
      : props.billingPastDue >= 0
      ? props.theme.colors.error
      : props.theme.colors.regent};
`;

export const getSelectStyles = () => ({
  control: (provided: any, { isDisabled }: { isDisabled: boolean }) => ({
    ...provided,
    width: '100%',
    marginBottom: 10,
    borderRadius: 10,
    border: '1px solid #8293A4',
    borderColor: '#8293A4',
    color: '#0E2941',
    opacity: isDisabled ? 0.6 : 1,
    backgroundColor: '#FFFFFF',
    minHeight: 40,
    borderWidth: 1,
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#8293A4',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    border: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#8293A4',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid #8293A4',
  }),
});

export const Refresh = styled(RefreshIcon)`
  width: 20px;
  height: 20px;
  margin: auto;
  cursor: pointer;
`;

export const Label = styled.label`
  white-space: ${(props: { isWrappableLabel: boolean }) =>
    props.isWrappableLabel ? 'normal' : 'nowrap'};
  margin: auto;
  display: ${(props: { withLabel: boolean }) =>
    props.withLabel ? 'block' : 'none'};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.colors.regent};
  opacity: ${(props: any) => (props.disabled ? '0.6' : '1')};
`;

export const ResultBPHLabel = styled.div`
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.colors.elephant};
  font-family: ${(props) => props.theme.fonts.medium};

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 140%;
    margin: 10px;
    text-align: center;
  }
`;

export const ResultBoxTableBPH = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.porcelain};
  height: 60px;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    border-radius: 5px;
    padding: 17px 0 11px 0;
  }
`;

export const ValueBPH = styled.div`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.pictonBlue};
  font-size: 26px;
  line-height: 129.19%;
  letter-spacing: 0.03em;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    margin-top: 7px;
    align-items: center;
  }
`;

export const ValueExplanationBPH = styled.div`
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.colors.regent};
  font-family: ${(props) => props.theme.fonts.regular};
  margin-right: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 0 15px;
    text-align: center;
  }
`;

export const Info = styled(InfoIcon)<{ disabled: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  &:hover {
    path {
      fill: ${(props) =>
        props.disabled
          ? props.theme.colors.regent
          : props.theme.colors.pictonBlue};
    }
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 300px;
  background-color: ${(props) => props.theme.colors.pictonBlue} !important;
  overflow-wrap: break-word;

  &.place-top {
    &:after {
      border-top-color: ${(props) => props.theme.colors.pictonBlue} !important;
    }
  }
`;

export const WhiteBackgroundLoading = styled(Loading)`
  background-color: white;
  margin: auto;
`;

export const Paragraph = styled.div`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.03em;
  color: #485663;
  font-family: ${(props) => props.theme.fonts.regular};
  margin: 0;
  padding: 0;
`;

export const SubTitle = styled.h3`
  font-size: ${isMobileOnly ? '16px' : '20px'};
  line-height: 28px;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.colors.elephant};
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.fonts.medium};
  text-align: left;
`;

export const Title = styled.h1`
  font-size: ${isMobileOnly ? '30px' : '34px'};
  line-height: 40px;
  letter-spacing: 0.03em;
  text-align: center;
  color: ${(props) => props.theme.colors.elephant};
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.fonts.medium};
`;

export const ToggleWrapper = styled.div`
  margin-bottom: 10px;
`;

export const OpacityAnimation = styled.div<{ key?: string }>`
  opacity: 0;
  transition: 1s;
  animation: show ${(props) => props.key} 0.7s 1;
  animation-fill-mode: forwards;

  @keyframes show${(props) => props.key} {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const EmptyPageInfoLabel = styled(Label)`
  text-align: center;
  font-size: ${() => (isMobile ? '19px' : '24px')};
  font-style: italic;
  line-height: 26px;
  color: ${(props) => props.theme.colors.regent};
  margin: auto;
  white-space: unset;
`;

export const AdditionalInfo = styled.div`
  line-height: 26px;
  letter-spacing: 0.03em;
  color: rgb(72, 86, 99);
  font-family: 'Roboto Regular', sans-serif;
  text-align: center;
  text-transform: none;
  font-size: calc(0.1vw + 1rem);
  white-space: pre-line;
`;
