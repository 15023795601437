import {
  createReportFiltersActions,
  reportFiltersReducer,
} from './reportFilters';
import { createNamedWrapperReducer } from '../utils/createNamedWrapperReducer';

const SSI_RESULTS = 'SSI_RESULTS';

export const ssiResultsReducer = createNamedWrapperReducer(
  reportFiltersReducer,
  SSI_RESULTS,
);
export const {
  toggleIdSelection,
  addReportId,
  removeReportId,
  removeAllReportIds,
  setFromPeriod,
  setToPeriod,
  selectTimePeriodName,
  selectDayPeriodName,
  selectDayPeriods,
  setDefaultTimePeriodName,
  setDefaultDayPeriodName,
  setDefaultDayPeriodValue,
} = createReportFiltersActions(SSI_RESULTS);
