import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';
import {ToastContainer, Zoom} from 'react-toastify';
import React from 'react';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 10px;
  }
`;

const AppToastContainer: React.FunctionComponent = () => (
    <StyledToastContainer closeOnClick={false} pauseOnFocusLoss={true} transition={Zoom} limit={5}/>
);

export default AppToastContainer;
