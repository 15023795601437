import { subMonths, subWeeks } from 'date-fns';
import { pluck } from 'ramda';
import { isMobile } from 'react-device-detect';
import {
  ClinicType,
  DoctorType,
  ISelectedListOption,
  PatientType,
} from './types';

export const UROFLOWMETRY_REPORTS_PER_PAGE = 20;
export const SSI_RESULTS_PER_PAGE = 20;
export const USERS_LIST_PAGE_SIZE = 20;
export const CHAT_MESSAGES_INTERVAL_MS = 5000;
export const USER_CHATS_POLL_INTERVAL_MS = 10000;
export const UNREAD_MESSAGES_INTERVAL_MS = 40000;
export const DEBOUNCE_TIME = 200;

export const CUSTOM_FILTERS_DATE_FORMAT = 'MM.dd.yyyy';
export const CUSTOM_MOMENT_DATE_FORMAT = 'MM.DD.yyyy';
export const CUSTOM_MOMENT_SHORT_YEAR_DATE_FORMAT = 'MM.DD.yy';
export const CUSTOM_DATE_FNS_FORMAT = 'MM.DD.YYYY';
export const CUSTOM_SHORT_YEAR_DATE_FNS_FORMAT = 'MM.DD.YY';
export const CUSTOM_LONG_DATE_FORMAT = 'MM.DD.YYYY HH:mm:ss';
export const CUSTOM_DATE_FORMAT_WITH_TIME = 'MM.DD.YYYY HH:mm';
export enum DayPeriodEnum {
  'Full day' = 'Full day',
  'Morning' = 'Morning',
  'Afternoon' = 'Afternoon',
  'Evening' = 'Evening',
  'Night' = 'Night',
  'Late Night' = 'Late Night',
}

export enum BillStatus {
  BillSuccessAndNoTests = -10001,
  BillSuccessAndTests = -10000,
  NotBilledAndTests = 10000,
}

export const BLADDER_DIARY_TYPES = [
  {
    label: 'All types',
    value: 'all',
  },
  {
    label: 'Voids',
    value: 'void',
  },
  {
    label: 'Drinks',
    value: 'water',
  },
  {
    label: 'Leaks',
    value: 'leakage',
  },
];

export const BLADDER_DIARY_SELECT_FIELDS = [
  {
    label: 'All fields',
    value: 'all',
  },
  {
    label: 'Max. Flow',
    value: 'max',
  },
  {
    label: 'Avg. Flow',
    value: 'average',
  },
  {
    label: 'Volume',
    value: 'volume',
  },
  {
    label: 'Voiding time',
    value: 'flowTime',
  },
  {
    label: 'Urge',
    value: 'urgeLevel',
  },
  {
    label: 'Urine color',
    value: 'urineColor',
  },
];

export const BLADDER_DIARY_TIME_PERIODS: {
  label: string;
  subtract: Record<string, string | number> | null;
}[] = [
  {
    label: 'Last 24 hrs',
    subtract: { value: 24, unit: 'h' },
  },
  {
    label: 'Last 48 hrs',
    subtract: { value: 48, unit: 'h' },
  },
  {
    label: 'Last 72 hrs',
    subtract: { value: 72, unit: 'h' },
  },
  {
    label: 'Last week',
    subtract: { value: 1, unit: 'w' },
  },
  {
    label: 'Last 2 weeks',
    subtract: { value: 2, unit: 'w' },
  },
  {
    label: 'Last month',
    subtract: { value: 1, unit: 'M' },
  },
  {
    label: 'Last 2 months',
    subtract: { value: 2, unit: 'M' },
  },
  {
    label: 'Last 3 months',
    subtract: { value: 3, unit: 'M' },
  },
  {
    label: 'Custom period',
    subtract: null,
  },
  {
    label: 'All results',
    subtract: null,
  },
];

export const TIME_PERIODS: IPeriod[] = [
  {
    label: 'All results',
    value: new Date(0),
  },
  {
    label: 'Last week',
    value: subWeeks(new Date(), 1),
  },
  {
    label: 'Last 2 weeks',
    value: subWeeks(new Date(), 2),
  },
  {
    label: 'Last month',
    value: subMonths(new Date(), 1),
  },
  {
    label: 'Last 2 months',
    value: subMonths(new Date(), 2),
  },
  {
    label: 'Last 3 months',
    value: subMonths(new Date(), 3),
  },
];

export const DAY_PERIOD: IPeriod[] = [
  {
    label: DayPeriodEnum['Full day'],
    value: null,
  },
  {
    label: DayPeriodEnum.Morning,
    value: [
      {
        from: new Date('2020T06:00'),
        to: new Date('2020T11:59'),
      },
    ],
  },
  {
    label: DayPeriodEnum.Afternoon,
    value: [
      {
        from: new Date('2020T12:00'),
        to: new Date('2020T16:59'),
      },
    ],
  },
  {
    label: DayPeriodEnum.Evening,
    value: [
      {
        from: new Date('2020T17:00'),
        to: new Date('2020T21:59'),
      },
    ],
  },
  {
    label: DayPeriodEnum.Night,
    value: [
      {
        from: new Date('2020T22:00'),
        to: new Date('2020T23:59'),
      },
      {
        from: new Date('2020T00:00'),
        to: new Date('2020T05:59'),
      },
    ],
  },
];

export const BLADDER_DIARY_DAY_PERIODS: IPeriod[] = [
  ...DAY_PERIOD,
  {
    label: DayPeriodEnum['Late Night'],
    value: null,
  },
];

export const messageFromErrorChangePayment = (error: Error): string =>
  error &&
  error.message.toLowerCase().includes('no payment method is available')
    ? 'Please select a payment method.'
    : messageFromError(error);

export const messageFromError = (error: Error): string =>
  error && error.message
    ? error.message.replace(/GraphQL error: /g, '')
    : 'Can not perform action';

export const messageFromAsyncError = (error: Error): string =>
  error && error.message
    ? error.message.replace(/Error: GraphQL error: /g, '')
    : 'Can not perform action';

export const labelsFromPeriods = (periods: IPeriod[]): string[] =>
  pluck('label', periods);

export interface IPeriod {
  label: string;
  value: IDayPeriodValue[] | Date;
}

export interface IDayPeriodValue {
  from: Date;
  to: Date;
}

export interface IResizableCols {
  width: string;
  none: number[];
}

export const generateColStyles = (
  resizable: IResizableCols[],
  colsSizes: number[],
  col: number,
): string => {
  let availableCols: number = colsSizes.length;
  const deletedIndex =
    resizable.findIndex((value) => value.none.includes(col)) + 1;
  return resizable
    .slice(0, deletedIndex || availableCols)
    .reduce((acc: string, value: IResizableCols) => {
      const freeWidth: number = value.none.reduce(
        (accNone: number, noneCol: number) => accNone + colsSizes[noneCol],
        0,
      );
      availableCols -= value.none.length;
      colsSizes = colsSizes.map(
        (calSize) => calSize + freeWidth / availableCols,
      );
      return `
      ${acc}
      @media(max-width: ${value.width}) {
        ${
          value.none.includes(col)
            ? `display: None`
            : `width ${colsSizes[col]}%`
        };
      }
    `;
    }, `width: ${colsSizes[col]}%;`);
};

export const newActivateDeactivate = (active: boolean): string =>
  !active ? 'activate' : 'deactivate';

export const closeDrawerAfterClick =
  (toggleDrawer: (isOpen: boolean) => void) => () => {
    isMobile && toggleDrawer(false);
  };

export const getDoctorForPatient = (patient: PatientType): DoctorType | null =>
  patient.doctors && patient.doctors.length ? patient.doctors[0] : null;

export const getClinicForPatient = (patient: PatientType): ClinicType | null =>
  patient.clinics && patient.clinics.length ? patient.clinics[0] : null;

export const doctorToOptionValue = (
  doctor: DoctorType,
): ISelectedListOption => ({
  value: doctor.id,
  label: `${doctor.firstName} ${doctor.lastName}`,
});

export const generatePromoCode = (lenght: number) =>
  [...Array(lenght)].map(() => Math.random().toString(36)[2]).join('');
