import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { userNotAuthenticatedAction } from '../reducers/user';
import { store } from './configureStore';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import { NO_INTERNET_ERROR_MESSAGE } from '../constants/localization';
import introspectionQueryResultData from '../fragmentTypes.json';

const generateApolloClient = ({ token }: IGenerateApolloParams) => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  //Please read the article to implement the refresh token logic: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some(
        (err: GraphQLError) => err.message === 'Not authenticated',
      )
    ) {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          Authorization: null,
        },
      });
      store.dispatch(userNotAuthenticatedAction());
    }
    if (networkError) {
      toast(NO_INTERNET_ERROR_MESSAGE, {
        type: 'error',
        toastId: 'network-error-message',
      });
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
      console.log(`[Network error]: ${networkError}`);
    }
  });

  let userLanguage = navigator?.language?.slice(0, 2);
  if (userLanguage?.length !== 2) {
    userLanguage = 'en';
  }

  return new ApolloClient({
    link: errorLink.concat(
      createUploadLink({
        uri: process.env.MENHEALTH_DEV_SERVER_URI,
        credentials: 'same-origin',
        headers: {
          'Accept-Language': userLanguage,
          Authorization: token,
        },
      }),
    ),
    cache: new InMemoryCache({ fragmentMatcher }),
  });
};

export { generateApolloClient };

interface IGenerateApolloParams {
  token: string | null;
}
