import { useEffect } from 'react';

const isNotUsingAnalytic = () =>
  process.env.MENHEALTH_DEV_SERVER_URI.includes('staging') ||
  process.env.MENHEALTH_DEV_SERVER_URI.includes('local');

const connectGtag = (): HTMLScriptElement => {
  const script: HTMLScriptElement = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.MENHEALTH_GOOGLE_ANALITIC_GTAG}`;
  script.async = true;

  document.head.appendChild(script);
  return script;
};

const initGtag = (title: string): HTMLScriptElement => {
  const script: HTMLScriptElement = document.createElement('script');
  script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${process.env.MENHEALTH_GOOGLE_ANALITIC_GTAG}', {
      page_path: window.location.pathname,
      page_title: '${title}'
    });
  `;
  return script;
};

export const useAnalytic = (title: string) => {
  useEffect(
    isNotUsingAnalytic()
      ? () => {}
      : () => {
          const scriptConnect: HTMLScriptElement = connectGtag();
          const scriptInit: HTMLScriptElement = initGtag(title);

          document.head.appendChild(scriptConnect);
          document.head.appendChild(scriptInit);

          return () => {
            document.head.removeChild(scriptConnect);
            document.head.removeChild(scriptInit);
          };
        },
    [title],
  );
};
