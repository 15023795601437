import { ActionType } from 'constants/types';
import { merge } from 'ramda';

export const PUT_UNREAD_MESSAGES_COUNT_TO_STORE_ACTION =
  'PUT_UNREAD_MESSAGES_COUNT_TO_STORE_ACTION';

export const putUnreadMessagesCountToStoreAction = (count: number) => ({
  type: PUT_UNREAD_MESSAGES_COUNT_TO_STORE_ACTION,
  payload: count,
});

export const initialState: IMessagingState = {
  channels: {},
  totalUnread: 0,
};

export const messagingReducer = (state = initialState, action: ActionType) =>
  action.type === PUT_UNREAD_MESSAGES_COUNT_TO_STORE_ACTION
    ? merge(state, { totalUnread: action.payload })
    : state;

export interface IMessagingState {
  channels: Object;
  totalUnread: number;
}
