import MobileFirstImage from 'assets/mobile_first_img.png';
import MobileSecondImage from 'assets/mobile_second_img.png';
import MobileThirdImage from 'assets/mobile_third_img.png';
import MobileFourthImage from 'assets/mobile_fourth_img.png';

export const PAGE_TITLE_MSG = 'MenHealth App';
export const TEXT_ONE_MSG =
  'Check your urinary health in the privacy of your home.';
export const TEXT_TWO_MSG =
  'Share the accurate test results with your doctor to help diagnose and manage urinary conditions.';
export const TEXT_THREE_MSG =
  'MenHealth® application does not require cleaning and is hygenic and user friendly. It provides clinically accurate urine flow measurement and enables men to be more aware of their medical conditions and to control them.';
export const PAGE_SECOND_TITLE_MSG = 'How It Works';

export const FEATURES = [
  {
    id: 'feat1',
    Image: MobileFirstImage,
    description:
      'Application starts to process urine flow when the patient says or presses “Start”',
  },
  {
    id: 'feat2',
    Image: MobileSecondImage,
    description:
      'After urinating, patient says or presses “stop” and application shows test results with parameters ' +
      '(maximum flow, average flow and volume)',
  },
  {
    id: 'feat3',
    Image: MobileThirdImage,
    description:
      'Test results are saved on the smartphone by default in the "History" and "Diary" pages. Patients ' +
      'can share test results with their physicians for any period of time (1 day 1 week, 2 weeks, custom range)',
  },
  {
    id: 'feat4',
    Image: MobileFourthImage,
    description: `"Test Diary" page presents previously taken test results (maximum flow, average flow and voided volume) with the date and time when the test was conducted`,
  },
];

export const MOBILE_LINK = 'https://app.myuroflow.com/start';
export const LINK_TO_APPSTORE =
  'https://apps.apple.com/us/app/menhealth/id1310623806?l=en&ls=1';
export const LINK_TO_GOOGLE_PLAY =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.menhealth';
