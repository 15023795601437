import { Dropin, Options } from 'braintree-web-drop-in';
import {
  IPriceCalculator,
  PaymentPlanType,
  SubscriptionType,
  UserType,
} from './types';
import logError from '../utils/logError';
import { toast } from 'react-toastify';
import {
  CLINIC_CUSTOM_PLAN_HEADER,
  DOCTOR_CUSTOM_PLAN_HEADER,
  GET_CUSTOM_PLAN_DEFAULT_DESCRIPTION,
} from './localization';

export const BRAINTREE_DROP_IN_UI_PROPS = (token: string): Options => ({
  authorization: token,
  container: '#dropin-container',
  paymentOptionPriority: [
    'card',
    'paypal',
    'paypalCredit',
    'venmo',
    'applePay',
    'googlePay',
  ],
  card: {
    cardholderName: { required: true },
    overrides: {},
  },
  paypal: {
    flow: 'vault',
    commit: true,
  },
});

export const getSubscriptionIds = (
  subscriptions: SubscriptionType[],
): string[] => subscriptions.map((subscription) => subscription.id);

export const getSubscriptionsForAction = (
  subscriptions: SubscriptionType[],
): SubscriptionType[] =>
  subscriptions.filter(
    (subscription: SubscriptionType) =>
      new Date(subscription.expiredDate).getTime() > new Date().getTime(),
  );

export const getSubscriptionSortValue = (
  first: SubscriptionType,
  second: SubscriptionType,
): 1 | 0 | -1 => {
  if (first.status === second.status) {
    return 0;
  }
  return first.status > second.status ? 1 : -1;
};

export const planCurrencyToSymbols = (currency: string): string => {
  switch (currency) {
    case 'USD':
      return '$';
    default:
      return '';
  }
};

export const normalizePlanPrice = (price: string): string =>
  price.replace('.00', '');

export const getPlanDescription = (descriptionPlan: string): string[] =>
  descriptionPlan.split('--').filter((value) => !!value);

export const getPriceCalculator = (
  user: UserType,
  currentFee: number,
): IPriceCalculator => {
  if (user.role === 'PATIENT') {
    return {
      getHeader: (plan: PaymentPlanType) =>
        plan.name.substring(plan.name.indexOf(':') + 1),
      getDescription: (plan: PaymentPlanType) =>
        getPlanDescription(plan.description),
      getPrice: (plan: PaymentPlanType) => {
        let planPrice = null;

        const clinicWithPlan = user.clinics.find(
          (clinic) => clinic.plan && clinic.plan.planId,
        );

        if (clinicWithPlan) {
          planPrice = clinicWithPlan.plan.price;
        }

        const doctorWithPlan = user.doctors.find(
          (doctor) => doctor.plan && doctor.plan.planId,
        );

        if (doctorWithPlan) {
          planPrice = doctorWithPlan.plan.price;
        }

        const userPlan = user.plan;

        if (userPlan && userPlan.planId) {
          planPrice = userPlan.price;
        }

        return planPrice
          ? planPrice.toString()
          : currentFee?.toString() ?? plan.price;
      },
    };
  } else if (user.role === 'DOCTOR') {
    return {
      getHeader: () => DOCTOR_CUSTOM_PLAN_HEADER,
      getDescription: () =>
        getPlanDescription(
          GET_CUSTOM_PLAN_DEFAULT_DESCRIPTION(user.maxPatients),
        ),
      getPrice: () => {
        if (currentFee) {
          return currentFee.toString();
        }

        const basePatientCount = getBasePatientCount(user.maxPatients);
        const additionalPatientCount = user.maxPatients - basePatientCount + 1;
        const fullPrice = getPatientCountBasedPrice(
          basePatientCount,
          additionalPatientCount,
        );

        return fullPrice.toFixed(0).toString();
      },
    };
  } else
    return {
      getHeader: () => CLINIC_CUSTOM_PLAN_HEADER,
      getDescription: () => [
        `${
          (!user.isNoLimitPatients ? user.maxPatients : null) ?? 'Unlimited'
        } Patients subscriptions`,
        `${
          (!user.isNoLimitDoctors ? user.maxDoctors : null) ?? 'Unlimited'
        } Doctors subscriptions`,
        ...getPlanDescription(
          GET_CUSTOM_PLAN_DEFAULT_DESCRIPTION(user.maxPatients),
        ),
      ],
      getPrice: () => {
        if (currentFee) {
          return currentFee.toString();
        }

        const basePatientCount = getBasePatientCount(user.maxPatients);
        const additionalPatientCount = user.maxPatients - basePatientCount + 1;
        const fullPrice = getPatientCountBasedPrice(
          basePatientCount,
          additionalPatientCount,
        );

        return fullPrice.toFixed(0).toString();
      },
    };
};

export const getBasePatientCount = (patientCount: number) => {
  const basePatientCount = [0, 26, 51, 101, 201].filter((definedThreshold) =>
    patientCount >= definedThreshold ? definedThreshold : 0,
  );
  return basePatientCount.reduce((oa, cur) => Math.max(oa, cur), 0);
};

export const getPatientCountBasedPrice = (
  basePatientCount: number,
  additionalPatientCount: number,
) => {
  const basePatientPrices: { [patientCount: number]: number } = {};
  basePatientPrices[0] = 100;
  basePatientPrices[26] = 100;
  basePatientPrices[51] = 225;
  basePatientPrices[101] = 425;
  basePatientPrices[201] = 625;

  if (basePatientPrices[basePatientCount]) {
    return (
      basePatientPrices[basePatientCount] +
      getAdditionalCountBasedPrice(basePatientCount, additionalPatientCount)
    );
  }
  return 0;
};

const getAdditionalCountBasedPrice = (
  basePatientCount: number,
  additionalPatientCount: number,
): number => {
  if (basePatientCount > 25 && basePatientCount <= 50) {
    return 5 * additionalPatientCount;
  }
  if (basePatientCount > 50 && basePatientCount <= 100) {
    return 4 * additionalPatientCount;
  }
  if (basePatientCount > 100 && basePatientCount <= 200) {
    return 2 * additionalPatientCount;
  }
  if (basePatientCount > 200) {
    return additionalPatientCount;
  }
  return 0;
};

export const priceCurrencyToString = (
  price: string,
  currency: string,
): string => {
  return `${planCurrencyToSymbols(currency)}${normalizePlanPrice(price)}`;
};

export const tearDownDropInInstance = (
  dropInInstance: Dropin,
  success: () => void,
) => {
  dropInInstance.teardown((error: Error | null) => {
    if (!error) {
      success();
    } else {
      logError('CANNOT TEARDOWN DROP IN INSTANCE', error);
      toast(error.message, { type: 'error' });
    }
  });
};
