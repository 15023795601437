import { createNamedWrapperReducer } from '../utils/createNamedWrapperReducer';
import {
  bladderDiaryFiltersReducer,
  createBladderDiaryFiltersActions,
} from './bladderDiaryFilters';

const BLADDER_DIARY = 'BLADDER_DIARY';

export const bladderDiaryReducer = createNamedWrapperReducer(
  bladderDiaryFiltersReducer,
  BLADDER_DIARY,
);
export const {
  setFilterByType,
  setSelectedField,
  setFromPeriod,
  setToPeriod,
  selectTimePeriodName,
  selectDayPeriodName,
} = createBladderDiaryFiltersActions(BLADDER_DIARY);
