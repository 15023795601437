import { applyMiddleware, createStore, AnyAction, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  userReducer,
  IUserState,
  USER_NOT_AUTHENTICATED_ACTION,
} from 'reducers/user';
import { appReducer, IAppState } from 'reducers/app';
import { messagingReducer, IMessagingState } from 'reducers/messaging';
import { uroflowmetryReportsReducer } from 'reducers/uroflowmetryReports';
import { USER_LOGOUT_ACTION } from 'reducers/user';
import createSagaMiddleware from 'redux-saga';
import { ssiReducer, ISsiState } from 'reducers/ssi';
import { PersistConfig } from 'redux-persist/es/types';
import { createBrowserHistory } from 'history';
import { IReportsFilterState } from '../reducers/reportFilters';
import { ssiResultsReducer } from '../reducers/ssiResults';
import { bladderDiaryReducer } from '../reducers/bladderDiary';
import { IBladderDiaryFilterState } from '../reducers/bladderDiaryFilters';

export interface IRootAppState {
  uroflowmetryReports: IReportsFilterState;
  app: IAppState;
  form: any;
  user: IUserState;
  router: RouterState;
  messaging: IMessagingState;
  ssi: ISsiState;
  ssiResults: IReportsFilterState;
  bladderDiary: IBladderDiaryFilterState;
}

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();

const createRootReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  user: userReducer,
  app: appReducer,
  uroflowmetryReports: uroflowmetryReportsReducer,
  messaging: messagingReducer,
  ssi: ssiReducer,
  ssiResults: ssiResultsReducer,
  bladderDiary: bladderDiaryReducer,
});

const persistorOptions: PersistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const resetEnhancer =
  (rootReducer: Reducer): any =>
  (state: IRootAppState, action: AnyAction) => {
    if (action.type === USER_LOGOUT_ACTION) {
      purgeStoredState(persistorOptions);
      //ToDo: It looks like a hack to redirect from the reducer
      document.location.replace(`${process.env.MENHEALTH_PUBLIC_URI}`);
    }
    if (action.type === USER_NOT_AUTHENTICATED_ACTION) {
      purgeStoredState(persistorOptions);
      //ToDo: It looks like a hack to redirect from the reducer
      document.location.replace('/signin?authError');
    }
    return rootReducer(state, action);
  };

const configureStore = (preloadedState: any) => {
  const middlewares = [routerMiddleware(history), sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const resultEnhancer =
    process.env.NODE_ENV === 'development'
      ? composedEnhancers
      : middlewareEnhancer;

  const store = createStore(
    resetEnhancer(persistReducer(persistorOptions, createRootReducer)),
    preloadedState,
    resultEnhancer,
  );

  return store;
};

const store = configureStore({});

let persistor = persistStore(store);

export { store, history, persistor };
