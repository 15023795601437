import React from 'react';
import styled from 'styled-components';

export const DownloadImageWrapper = styled.img`
  max-width: 135px;
  margin: auto;
  max-height: 45px;
`;

const ApplicationLink: React.FunctionComponent<IProps> = ({
  src,
  alt,
  link,
}) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <DownloadImageWrapper src={src} alt={alt} />
  </a>
);

export default ApplicationLink;

interface IProps {
  src: string;
  alt: string;
  link: string;
}
