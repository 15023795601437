import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { connect, Provider } from 'react-redux';
import { history, persistor, store } from 'utils/configureStore';
import { ApolloProvider } from 'react-apollo';
import { generateApolloClient } from 'utils/configureApollo';
import { PersistGate } from 'redux-persist/integration/react';
import { pathOr } from 'ramda';
import { ThemeProvider } from 'styled-components';
import { theme } from 'constants/styled';
import 'rc-slider/assets/index.css';
import 'normalize.css';
import './index.css';
import { ConnectedRouter as Router } from 'connected-react-router';

const ApolloWrapper = connect((state) => ({
  token: pathOr(null, ['user', 'token'], state),
  role: pathOr(null, ['user', 'role'], state),
  signed: pathOr(null, ['user', 'signed'], state),
  email: pathOr(null, ['user', 'email'], state),
}))(({ token, role, signed, email }) => (
  <ApolloProvider client={generateApolloClient({ token })}>
    <App role={role} token={token} signed={signed} email={email} />
  </ApolloProvider>
));

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store as any}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ApolloWrapper />
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
