import { ActionType } from 'constants/types';
import { merge, reject, equals } from 'ramda';
import {
  DAY_PERIOD,
  IDayPeriodValue,
  IPeriod,
  TIME_PERIODS,
} from 'constants/ui';

const TOGGLE_ID_SELECTION = 'TOGGLE_ID_SELECTION';
const ADD_REPORT_ID = 'ADD_REPORT_ID';
const REMOVE_REPORT_ID = 'REMOVE_REPORT_ID';
const REMOVE_ALL_REPORT_IDS = 'REMOVE_ALL_REPORT_IDS';
const SET_FROM_PERIOD = 'SET_FROM_PERIOD';
const SET_TO_PERIOD = 'SET_TO_PERIOD';
const SELECT_TIME_PERIOD_NAME = 'SELECT_TIME_PERIOD_NAME';
const SELECT_DAY_PERIOD_NAME = 'SELECT_DAY_PERIOD_NAME';
const SELECT_DAY_PERIODS = 'SELECT_DAY_PERIODS';

const defaultDayPeriod = (): IPeriod => DAY_PERIOD[0];
const defaultTimePeriod = (): IPeriod => TIME_PERIODS[0];

export function createReportFiltersActions(storeName: string) {
  const toggleIdSelection = () => ({
    type: TOGGLE_ID_SELECTION,
    storeName,
  });

  const addReportId = (id: string) => ({
    type: ADD_REPORT_ID,
    payload: { id },
    storeName,
  });

  const removeReportId = (id: string) => ({
    type: REMOVE_REPORT_ID,
    payload: { id },
    storeName,
  });

  const removeAllReportIds = () => ({
    type: REMOVE_ALL_REPORT_IDS,
    storeName,
  });

  const setFromPeriod = (from: Date) => ({
    type: SET_FROM_PERIOD,
    payload: { from },
    storeName,
  });

  const setToPeriod = (to: Date) => ({
    type: SET_TO_PERIOD,
    payload: { to },
    storeName,
  });

  const selectTimePeriodName = (name: string) => ({
    type: SELECT_TIME_PERIOD_NAME,
    payload: { name },
    storeName,
  });

  const selectDayPeriodName = (name: string) => ({
    type: SELECT_DAY_PERIOD_NAME,
    payload: { name },
    storeName,
  });

  const selectDayPeriods = (dayPeriods: IDayPeriodValue) => ({
    type: SELECT_DAY_PERIODS,
    payload: { dayPeriods },
    storeName,
  });

  const setDefaultTimePeriodName = () => ({
    type: SELECT_TIME_PERIOD_NAME,
    payload: { name: defaultTimePeriod().label },
    storeName,
  });

  const setDefaultDayPeriodName = () => ({
    type: SELECT_DAY_PERIOD_NAME,
    payload: { name: defaultDayPeriod().label },
    storeName,
  });

  const setDefaultDayPeriodValue = () => ({
    type: SELECT_DAY_PERIODS,
    payload: { dayPeriods: defaultDayPeriod().value },
    storeName,
  });

  return {
    toggleIdSelection,
    addReportId,
    removeReportId,
    removeAllReportIds,
    setFromPeriod,
    setToPeriod,
    selectTimePeriodName,
    selectDayPeriodName,
    selectDayPeriods,
    setDefaultTimePeriodName,
    setDefaultDayPeriodName,
    setDefaultDayPeriodValue,
  };
}

export interface IReportsFilterState {
  from: Date;
  to: Date;
  dayPeriods: IDayPeriodValue[];
  selectedIds: string[];
  isIdSelectionEnabled: boolean;
  selectedTimePeriodName: string;
  selectedDayPeriodName: string;
}

const initialState: IReportsFilterState = {
  from: null,
  to: null,
  dayPeriods: null,
  selectedIds: [],
  isIdSelectionEnabled: false,
  selectedTimePeriodName: defaultTimePeriod().label,
  selectedDayPeriodName: defaultDayPeriod().label,
};

export const reportFiltersReducer = (
  state = initialState,
  action: ActionType,
) => {
  switch (action.type) {
    case TOGGLE_ID_SELECTION:
      return merge(state, {
        isIdSelectionEnabled: !state.isIdSelectionEnabled,
        selectedIds: [],
      });

    case ADD_REPORT_ID:
      return merge(state, {
        selectedIds: [...state.selectedIds, action.payload.id],
      });

    case REMOVE_REPORT_ID:
      return merge(state, {
        selectedIds: reject(equals(action.payload.id), state.selectedIds),
      });

    case REMOVE_ALL_REPORT_IDS:
      return merge(state, { selectedIds: [] });

    case SET_FROM_PERIOD:
      return merge(state, { from: action.payload.from, selectedIds: [] });

    case SET_TO_PERIOD:
      return merge(state, { to: action.payload.to, selectedIds: [] });

    case SELECT_TIME_PERIOD_NAME:
      return merge(state, { selectedTimePeriodName: action.payload.name });

    case SELECT_DAY_PERIODS:
      return merge(state, { dayPeriods: action.payload.dayPeriods });

    case SELECT_DAY_PERIOD_NAME:
      return merge(state, { selectedDayPeriodName: action.payload.name });

    default:
      return state;
  }
};


