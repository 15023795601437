import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

export const SPINNER_PATIENT_CLASS_NAME = 'loading_patient_spinner';
export const SPINNER_SMALL_CLASS_NAMES = 'loading_small_spinner';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.mercury};
  display: flex;
  align-items: center;
  justify-content: center;

  & .loading_patient_spinner {
    width: 100px;
    height: 100px;
  }

  & .loading_small_spinner {
    width: 40px;
    height: 40px;
  }
`;

const Loading: React.FunctionComponent<IProps> = ({
  className,
  spinnerClassName = SPINNER_PATIENT_CLASS_NAME,
}) => (
  <Wrapper className={className}>
    <Spinner
      fadeIn={'quarter'}
      className={spinnerClassName}
      name="double-bounce"
      color="#57bbc8"
    />
  </Wrapper>
);

export default Loading;

interface IProps {
  className?: string;
  spinnerClassName?: string;
}
