import { ActionType } from 'constants/types';
import { merge } from 'ramda';

const UPDATE_SSI_ANSWERED_LIST_ACTION = 'UPDATE_SSI_ANSWERED_LIST';
const NAVIGATE_TO_QUESTION_ACTION = 'NAVIGATE_TO_QUESTION_ACTION';
const CLEAR_SSI_RESULTS = 'CLEAR_SSI_RESULTS';

export const updateSsiAnsweredListAction = ({
  questionId,
  answer,
  shouldIncrement,
}: UpdateSsiAnsweredListActionType) => ({
  type: UPDATE_SSI_ANSWERED_LIST_ACTION,
  payload: {
    questionId,
    answer,
    shouldIncrement,
  },
});

export const navigateToQuestionAction = (index: number) => ({
  type: NAVIGATE_TO_QUESTION_ACTION,
  payload: index,
});

export const clearSsiResults = () => ({
  type: CLEAR_SSI_RESULTS,
})

const initialState: ISsiState = {
  currentQuestionIndex: 0,
  answered: {},
};

export const ssiReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case NAVIGATE_TO_QUESTION_ACTION:
      return merge(state, { currentQuestionIndex: action.payload });
    case UPDATE_SSI_ANSWERED_LIST_ACTION:
      return merge(state, {
        answered: merge(state.answered, {
          [action.payload.questionId]: {
            answer: action.payload.answer,
            shouldIncrement: action.payload.shouldIncrement,
          },
        }),
      });
    case CLEAR_SSI_RESULTS:
      return merge(state, { currentQuestionIndex: 0, answered: {} });
    default:
      return state;
  }
};

export interface ISsiState {
  currentQuestionIndex: number;
  answered: IAnswered;
}

export interface IAnswered {
  [questionId: string]: {
    shouldIncrement: boolean;
    answer: {
      label: string;
      points: number;
    };
  };
}

export type UpdateSsiAnsweredListActionType = {
  questionId: string;
  answer: any;
  shouldIncrement: boolean;
};
