import styled from 'styled-components';

export const RowAvatar = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props: { url: string; theme: any }) =>
    props.url ? 'transparent' : props.theme.colors.regent};
  border-radius: 50%;
  background-image: url(${(props: { url: string }) => props.url});
  background-size: cover;
  background-position: center;
`;
