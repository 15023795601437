import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {
  ACCEPT_LABEL,
  ARIA_ACCEPT_LABEL,
  CONSENT_TEXT,
} from '../consentLabels';
import styles from './cookiesConsentMobile.module.css';

const AppCookiesConsentMobile = () => (
  <CookieConsent
    overlay
    location={'top'}
    buttonClasses={styles.acceptButton}
    containerClasses={styles.alertContainer}
    contentClasses={styles.content}
    buttonText={ACCEPT_LABEL}
    ariaAcceptLabel={ARIA_ACCEPT_LABEL}
  >
    {CONSENT_TEXT}
  </CookieConsent>
);

export default AppCookiesConsentMobile;
