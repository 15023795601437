import React from 'react';
import styled from 'styled-components';
import {
  HIPAA_DISCLAIMER_MSG,
  PRIVACY_POLICY_MSG,
  GET_RIGHTS_MSG,
  PATENTS_MSG,
  CONTACT_MAIL_ADDRESS_MSG,
  TERMS_AND_CONDITIONS_MSG,
} from 'constants/localization';
import {
  HIPAA_DISCLAIMER_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from 'constants/routes';
import { Link } from 'react-router-dom';
import SocialNetworks from './SocialNetworks';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px 40px 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  background-color: #8293a4;
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 22px 0;
`;

const Lnk = styled(Link)`
  color: white;
  text-decoration: none;
  color: #8ff2fe;
  margin-right: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RightsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightsBlock = styled.div`
  margin-bottom: 18px;
  color: white;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MobileFooter = () => {
  return (
    <Wrapper>
      <SocialNetworks mobileMode={true} />
      <Links>
        <Lnk target={'_tab'} to={HIPAA_DISCLAIMER_ROUTE}>
          {HIPAA_DISCLAIMER_MSG}
        </Lnk>
        <Lnk target={'_tab'} to={PRIVACY_POLICY_ROUTE}>
          {PRIVACY_POLICY_MSG}
        </Lnk>
        <Lnk target={'_tab'} to={TERMS_AND_CONDITIONS_ROUTE}>
          {TERMS_AND_CONDITIONS_MSG}
        </Lnk>
      </Links>
      <RightsContainer>
        <RightsBlock>{GET_RIGHTS_MSG()}</RightsBlock>
        <RightsBlock>{PATENTS_MSG}</RightsBlock>
        <RightsBlock>{CONTACT_MAIL_ADDRESS_MSG}</RightsBlock>
      </RightsContainer>
    </Wrapper>
  );
};

export default MobileFooter;
