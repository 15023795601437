import { ActionType } from '../constants/types';

export function createNamedWrapperReducer<S>(
  reducerFunction: (state: S, action: ActionType) => S,
  reducerName: string,
) {
  return (state: S, action: ActionType) => {
    const { storeName } = action;
    const isInitializationCall = state === undefined;
    if (storeName !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
}
