import React from 'react';
import styles from './socialNetwork.module.css';
import ApplicationLinks from '../../Modals/ApplicationLinkTypes/ApplicationLinks';
import MailIcon from '../../../assets/social_networks/mail.svg';
import TwitterIcon from '../../../assets/social_networks/twitter.svg';
import LinkedinIcon from '../../../assets/social_networks/linkedin.svg';
import PhoneIcon from '../../../assets/social_networks/phone.png';
import {
  APP_CALL_US,
  APP_EMAIL_MSG,
  CONTACT_PHONE,
  FOLLOW_US,
} from '../../../constants/localization';

const SocialNetworks: React.FunctionComponent<IProps> = ({
  mobileMode = false,
}) => (
  <div
    className={
      mobileMode
        ? `${styles.socialNetworkContainer} ${styles.mobile}`
        : styles.socialNetworkContainer
    }
  >
    <ApplicationLinks />
    <div className={styles.iconsBlock}>
      <a
        href="https://twitter.com/MyUroflow"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.icon}
          src={TwitterIcon}
          alt={`${FOLLOW_US('Twitter')}`}
          title={`${FOLLOW_US('Twitter')}`}
        />
      </a>
      <a
        href="https://www.linkedin.com/company/menhealth"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.icon}
          src={LinkedinIcon}
          alt={`${FOLLOW_US('Linkedin')}`}
          title={`${FOLLOW_US('Linkedin')}`}
        />
      </a>
      <a
        href={`mailto:${process.env.MENHEALTH_CONTACT_EMAIL_ADDRESS}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.icon}
          src={MailIcon}
          alt={APP_EMAIL_MSG}
          title={APP_EMAIL_MSG}
        />
      </a>
      <a
        href={`tel:${CONTACT_PHONE}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.icon}
          src={PhoneIcon}
          alt={APP_CALL_US}
          title={APP_CALL_US}
        />
      </a>
    </div>
  </div>
);

export default SocialNetworks;

interface IProps {
  mobileMode?: boolean;
}
