import { ActionType } from 'constants/types';
import { merge } from 'ramda';
import {
  BLADDER_DIARY_DAY_PERIODS,
  BLADDER_DIARY_TIME_PERIODS,
  IPeriod,
} from 'constants/ui';
import dayjs from 'utils/dayjs';

const SET_FROM_PERIOD = 'SET_FROM_PERIOD';
const SET_TO_PERIOD = 'SET_TO_PERIOD';
const SELECT_TIME_PERIOD_NAME = 'SELECT_TIME_PERIOD_NAME';
const SELECT_DAY_PERIOD_NAME = 'SELECT_DAY_PERIOD_NAME';
const SET_FILTER_BY_TYPE = 'SET_FILTER_BY_TYPE';
const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD';

const defaultDayPeriod = (): IPeriod => BLADDER_DIARY_DAY_PERIODS[0];
const defaultTimePeriod = (): Pick<IPeriod, 'label'> =>
  BLADDER_DIARY_TIME_PERIODS[0];

export function createBladderDiaryFiltersActions(storeName: string) {
  const setFilterByType = (
    filterType: keyof IBladderDiaryFilterState['type'],
    value: boolean,
  ) => ({
    type: SET_FILTER_BY_TYPE,
    payload: { filterType, value },
    storeName,
  });
  const setSelectedField = (
    selectedField: keyof IBladderDiaryFilterState['uroflowmetryFields'],
    value: boolean,
  ) => ({
    type: SET_SELECTED_FIELD,
    payload: { selectedField, value },
    storeName,
  });

  const setFromPeriod = (from: Date) => ({
    type: SET_FROM_PERIOD,
    payload: { from },
    storeName,
  });

  const setToPeriod = (to: Date) => ({
    type: SET_TO_PERIOD,
    payload: { to },
    storeName,
  });

  const selectTimePeriodName = (name: string) => ({
    type: SELECT_TIME_PERIOD_NAME,
    payload: { name },
    storeName,
  });

  const selectDayPeriodName = (name: string) => ({
    type: SELECT_DAY_PERIOD_NAME,
    payload: { name },
    storeName,
  });

  return {
    setFilterByType,
    setSelectedField,
    setFromPeriod,
    setToPeriod,
    selectTimePeriodName,
    selectDayPeriodName,
  };
}

export interface IBladderDiaryFilterState {
  type: {
    all: boolean;
    void: boolean;
    water: boolean;
    leakage: boolean;
  };
  from: Date;
  to: Date;
  selectedTimePeriodName: string;
  selectedDayPeriodName: string;
  uroflowmetryFields: {
    all: boolean;
    max: boolean;
    average: boolean;
    volume: boolean;
    flowTime: boolean;
    urgeLevel: boolean;
    urineColor: boolean;
  };
}

const initialState: IBladderDiaryFilterState = {
  type: {
    all: true,
    void: false,
    water: false,
    leakage: false,
  },
  from: dayjs()
    .utc()
    .subtract(
      BLADDER_DIARY_TIME_PERIODS[0].subtract.value,
      BLADDER_DIARY_TIME_PERIODS[0].subtract.unit,
    )
    .toDate(),
  to: dayjs().utc().toDate(),
  selectedTimePeriodName: defaultTimePeriod().label,
  selectedDayPeriodName: defaultDayPeriod().label,
  uroflowmetryFields: {
    all: true,
    max: false,
    average: false,
    volume: false,
    flowTime: false,
    urgeLevel: false,
    urineColor: false,
  },
};

export const bladderDiaryFiltersReducer = (
  state = initialState,
  action: ActionType,
) => {
  switch (action.type) {
    case SET_FROM_PERIOD:
      return merge(state, { from: action.payload.from });

    case SET_TO_PERIOD:
      return merge(state, { to: action.payload.to });

    case SELECT_TIME_PERIOD_NAME:
      return merge(state, { selectedTimePeriodName: action.payload.name });

    case SELECT_DAY_PERIOD_NAME:
      return merge(state, { selectedDayPeriodName: action.payload.name });

    case SET_FILTER_BY_TYPE: {
      const { filterType, value } = action.payload;
      const newTypeState = { ...state.type };

      if (filterType === 'all' && value) {
        Object.keys(newTypeState).forEach((key) => {
          newTypeState[key as keyof IBladderDiaryFilterState['type']] = false;
        });
        newTypeState['all'] = true;
      } else if (filterType !== 'all' && value && newTypeState['all']) {
        newTypeState['all' as keyof IBladderDiaryFilterState['type']] = false;
      }

      newTypeState[filterType as keyof IBladderDiaryFilterState['type']] =
        value;

      const selectedCount = Object.values(newTypeState).filter(Boolean).length;

      if (selectedCount === 0) {
        return state;
      }

      return merge(state, { type: newTypeState });
    }

    case SET_SELECTED_FIELD: {
      const { selectedField, value } = action.payload;
      const newUroflowmetryFieldsState = { ...state.uroflowmetryFields };

      if (selectedField === 'all' && value) {
        Object.keys(newUroflowmetryFieldsState).forEach((key) => {
          newUroflowmetryFieldsState[
            key as keyof IBladderDiaryFilterState['uroflowmetryFields']
          ] = false;
        });
        newUroflowmetryFieldsState['all'] = true;
      } else if (
        selectedField !== 'all' &&
        value &&
        newUroflowmetryFieldsState['all']
      ) {
        newUroflowmetryFieldsState[
          'all' as keyof IBladderDiaryFilterState['uroflowmetryFields']
        ] = false;
      }

      newUroflowmetryFieldsState[
        selectedField as keyof IBladderDiaryFilterState['uroflowmetryFields']
      ] = value;

      const selectedCount = Object.values(newUroflowmetryFieldsState).filter(
        Boolean,
      ).length;

      if (selectedCount === 0) {
        return state;
      }

      return merge(state, { uroflowmetryFields: newUroflowmetryFieldsState });
    }
    default:
      return state;
  }
};
