export const LANDING_ANALYTIC_PAGE = 'landing';
export const LANDING_ABOUT_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} about`;
export const LANDING_CONTACT_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} contact`;
export const LANDING_INDEX_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} index`;
export const LANDING_MENHEALTH_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} for patients`;
export const LANDING_PLATFORM_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} for doctors`;
export const LANDING_PRICING_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} pricing`;
export const LANDING_SIGN_UP_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} sign up`;
export const LANDING_SUBSCRIPTIONS_ANALYTIC_PAGE = `${LANDING_ANALYTIC_PAGE} subscriptions`;

export const LICENSE_ANALYTIC_PAGE = 'license';
export const LICENSE_HIPAA_ANALYTIC_PAGE = `${LICENSE_ANALYTIC_PAGE} hipaa`;
export const LICENSE_PRIVACY_ANALYTIC_PAGE = `${LICENSE_ANALYTIC_PAGE} privacy policy`;
export const LICENSE_TERMS_AND_CONDITIONS_PAGE = `${LICENSE_ANALYTIC_PAGE} terms and conditions`;

export const AUTH_ANALYTIC_PAGE = 'auth';
export const AUTH_RESET_PASSWORD_ANALYTIC_PAGE = `${AUTH_ANALYTIC_PAGE} reset password`;
export const AUTH_SET_PASSWORD_ANALYTIC_PAGE = `${AUTH_ANALYTIC_PAGE} set password`;
export const AUTH_SIGN_IN_ANALYTIC_PAGE = `${AUTH_ANALYTIC_PAGE} sign in`;

export const ADMIN_ANALYTIC_PAGE = 'admin';
export const ADMIN_DOCTOR_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} doctor`;
export const ADMIN_DOCTORS_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} doctors`;
export const ADMIN_PATIENT_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} patients`;
export const ADMIN_MEDICINES_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} medicines`;
export const ADMIN_PROCEDURES_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} procedures`;
export const ALGORITHM_COEFFICIENTS_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} algorithm coefficients`;
export const ADMIN_CLINICS_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} clinics`;
export const ADMIN_PROMO_CODES_ANALYTIC_PAGE = `${ADMIN_ANALYTIC_PAGE} promo codes`;

export const CHAT_ANALYTIC_PAGE = 'chat';
export const CHAT_LIST_ANALYTIC_PAGE = `${CHAT_ANALYTIC_PAGE} list`;

export const DOCTOR_ANALYTIC_PAGE = 'doctor';
export const DOCTOR_CLINICS_ANALYTIC_PAGE = `${DOCTOR_ANALYTIC_PAGE} clinics`;
export const DOCTOR_PATIENT_ANALYTIC_PAGE = `${DOCTOR_ANALYTIC_PAGE} patient`;

export const PATIENT_ANALYTIC_PAGE = 'patient';
export const PATIENT_DIARY_ANALYTIC_PAGE = `${PATIENT_ANALYTIC_PAGE} diary`;
export const PATIENT_BLADDER_DIARY_ANALYTIC_PAGE = `${PATIENT_ANALYTIC_PAGE} bladder diary`;
export const PATIENT_PROVIDER_LIST_ANALYTIC_PAGE = `${PATIENT_ANALYTIC_PAGE} my doctors`;
export const PATIENT_SSI_ANALYTIC_PAGE = `${PATIENT_ANALYTIC_PAGE} ssi`;

export const CLINIC_ANALYTIC_PAGE = 'clinic';
export const CLINIC_DOCTORS_ANALYTIC_PAGE = `${CLINIC_ANALYTIC_PAGE} doctors`;
export const CLINIC_PATIENTS_ANALYTIC_PAGE = `${CLINIC_ANALYTIC_PAGE} patients`;
export const CLINIC_EDIT_PATIENT_ANALYTIC_PAGE = `${CLINIC_ANALYTIC_PAGE} edit patient`;
export const IMPORT_PATIENTS_ANALYTIC_PAGE = `import patients`;

export const EDIT_PROFILE_ANALYTIC_PAGE = 'edit profile';
export const SUBSCRIBED_USER_PLANS_ANALYTIC_PAGE = 'subscribed user plans';
export const BLOCKED_USER_PLANS_ANALYTIC_PAGE = 'blocked user plans';
export const PENDING_USER_PLANS_ANALYTIC_PAGE = 'pending user plans';
export const UNSUBSCRIBED_USER_PLANS_ANALYTIC_PAGE = 'unsubscribed user plans';

export const LOADING_FROM_TOKEN_ANALYTIC_PAGE = 'Loading from token';
