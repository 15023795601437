import React from 'react';
import Helmet from 'react-helmet';

const PageTitle: React.FunctionComponent<IProps> = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

export default PageTitle;

interface IProps {
  title: string;
}
