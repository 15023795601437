import ApplicationLink from './ApplicationLink';
import AppstorDownloadImg from '../../../assets/appstore_download.png';
import {
  LINK_TO_APPSTORE,
  LINK_TO_GOOGLE_PLAY,
} from '../../../pages/Landing/LnMenHealth/constants';
import GoogleplayDownloadImg from '../../../assets/googleplay_download.png';
import React from 'react';
import styled from 'styled-components';
import Separator from '../../Separator';

const AppLinks = styled.div`
  display: flex;
`;

export const AppLnk = styled.div`
  border-radius: 40px;
  overflow: hidden;
  margin: auto;
  border: 1.4px solid white;
  background-color: ${(props) => props.theme.colors.regent};
  max-width: 160px;
  max-height: 50px;
`;

const AppStoreLink: React.FunctionComponent = () => (
  <AppLnk>
    <ApplicationLink
      src={AppstorDownloadImg}
      alt={'Download app for ios'}
      link={LINK_TO_APPSTORE}
    />
  </AppLnk>
);

const GooglePlayAppLink: React.FunctionComponent = () => (
  <AppLnk>
    <ApplicationLink
      src={GoogleplayDownloadImg}
      alt={'Download app for android'}
      link={LINK_TO_GOOGLE_PLAY}
    />
  </AppLnk>
);

const ApplicationLinks: React.FunctionComponent = () => {
  return (
    <AppLinks>
      <AppStoreLink />
      <Separator />
      <GooglePlayAppLink />
    </AppLinks>
  );
};

export default ApplicationLinks;
export { AppStoreLink, GooglePlayAppLink };
