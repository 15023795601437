import React from 'react';
import styled from 'styled-components';
import { ReactComponent as HippaIcon } from 'assets/hippa_compliant.svg';
import { ReactComponent as ClinicalIcon } from 'assets/clinical_grade.svg';
import { ReactComponent as PatentedIcon } from 'assets/patented_technology.svg';
import { ReactComponent as FdaIcon } from 'assets/fda_icon.svg';
import { ReactComponent as SanitaryIcon } from 'assets/sanitary_icon.svg';
import { isMobileOnly } from 'react-device-detect';

const HIPPA_COMPLIANT_MSG = `HIPAA\ncompliant`;
const FDA_MSG = 'FDA\ncleared';
const PATENTED_MSG = 'Patented\ntechnology';
const CLINICAL_MSG = 'Clinical grade\naccuracy';
const SANITARY_MSG = 'Sanitary with no\nwaste handling';

const Wrapper = styled.div`
  display: ${isMobileOnly ? 'none' : 'block'};
  width: 100%;
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  max-width: 1024px;
`;

const Block = styled.div`
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #8293a4;
  font-family: ${(props) => props.theme.fonts.regular};
  white-space: pre-wrap;
`;

const Banner: React.FunctionComponent = () => (
  <Wrapper>
    <Container>
      <Block>
        <HippaIcon />
        <Text>{HIPPA_COMPLIANT_MSG}</Text>
      </Block>
      <Block>
        <FdaIcon />
        <Text>{FDA_MSG}</Text>
      </Block>
      <Block>
        <PatentedIcon />
        <Text>{PATENTED_MSG}</Text>
      </Block>
      <Block>
        <ClinicalIcon width="32px" />
        <Text>{CLINICAL_MSG}</Text>
      </Block>
      <Block>
        <SanitaryIcon />
        <Text>{SANITARY_MSG}</Text>
      </Block>
    </Container>
  </Wrapper>
);

export default Banner;
