import gql from 'graphql-tag';

export const MY_CHATS_QUERY = gql`
  query myChats {
    myChats {
      id
      title
      createdAt
      unreadCount
      members {
        id
        userId
        username
        isOnline
        role
        avatarUrl
      }
      lastMessage {
        id
        content
        author {
          id
          userId
        }
        createdAt
        status
      }
    }
    unreadMessagesCount
  }
`;

export const UNREAD_CHAT_MESSAGES = gql`
  query unreadMessagesCount {
    unreadMessagesCount
  }
`;

export const CHAT_MESSAGES_BY_ID = gql`
  query chatMessagesById($chatId: String!, $lastMessageId: String) {
    chatMessagesById(chatId: $chatId, lastMessageId: $lastMessageId) {
      isUpdated
      messages {
        id
        content
        author {
          id
          userId
        }
        createdAt
        status
        createdOnUI
      }
    }
  }
`;

export const GET_DIRECT_CHAT = gql`
  query getDirectChat(
    $directChatWithUserId: String!
    $directChatWithRole: Role!
  ) {
    directChat: getDirectChat(
      directChatWithUserId: $directChatWithUserId
      directChatWithRole: $directChatWithRole
    ) {
      id
      title
      members {
        id
        userId
        username
        isOnline
        role
        avatarUrl
      }
    }
  }
`;

export const GET_DIRECT_CHAT_BY_ID = gql`
  query getDirectChatById($chatId: String!) {
    directChat: getDirectChatById(chatId: $chatId) {
      id
      title
      members {
        id
        userId
        username
        isOnline
        role
        avatarUrl
      }
    }
  }
`;

export const CREATE_DIRECT_CHAT = gql`
  mutation createDirectChat(
    $directChatWithUserId: String!
    $directChatWithRole: Role!
  ) {
    directChat: createDirectChat(
      directChatWithUserId: $directChatWithUserId
      directChatWithRole: $directChatWithRole
    ) {
      id
      title
      members {
        id
        userId
        username
        isOnline
        role
        avatarUrl
      }
    }
  }
`;

export const MARK_CHAT_AS_READ = gql`
  mutation markChatAsRead($groupChatId: String!) {
    unreadMessagesCount: markChatAsRead(groupChatId: $groupChatId)
  }
`;

export const SEND_MESSAGE_TO_CHAT = gql`
  mutation sendMessageToChat(
    $chatId: String!
    $content: String!
    $created: DateTime!
  ) {
    message: sendMessageToGroup(
      chatId: $chatId
      content: $content
      created: $created
    ) {
      id
    }
  }
`;
