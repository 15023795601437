import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {
  ACCEPT_LABEL,
  ARIA_ACCEPT_LABEL,
  CONSENT_TEXT,
} from '../consentLabels';
import styles from './cookiesConsentDesktop.module.css';

const AppCookiesConsent = () => (
  <CookieConsent
    overlay
    buttonClasses={styles.acceptButton}
    contentClasses={styles.content}
    containerClasses={styles.alertContainer}
    buttonText={ACCEPT_LABEL}
    ariaAcceptLabel={ARIA_ACCEPT_LABEL}
  >
    {CONSENT_TEXT}
  </CookieConsent>
);
export default AppCookiesConsent;
