import React from 'react';
import Spinner from 'react-spinkit';

const ButtonLoader: React.FunctionComponent = ({ children }) => (
  <React.Fragment>
    {children}
    <Spinner
      style={{ position: 'absolute' }}
      fadeIn={'quarter'}
      name="double-bounce"
      color="#57bbc8"
    />
  </React.Fragment>
);

export default ButtonLoader;
