import {
    MedicineType,
    ProcedureType,
    UserRoleType,
    UserType,
} from 'constants/types';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
    ABOUT_ROUTE,
    ADMIN_CLINICS_ROUTE,
    ADMIN_DOCTORS_ROUTE,
    ADMIN_MEDICINES_ROUTE,
    ADMIN_PATIENT_ROUTE,
    ADMIN_PLANS_ROUTE,
    ADMIN_PROCEDURES_ROUTE,
    ALGORITHM_COEFFICIENTS_ROUTE_MH,
    ALGORITHM_COEFFICIENTS_ROUTE_SR,
    BLADDER_DIARY_ROUTE,
    CHOOSING_PLAN_ROUTE,
    CLINIC_DOCTORS_ROUTE,
    CLINIC_PATIENTS_ROUTE,
    CONTACT_ROUTE,
    DIARY_ROUTE,
    DOCTOR_CLINIC_ROUTE,
    DOCTOR_ROUTE,
    EDIT_CLINIC_PATIENT_BY_ID_ROUTER,
    FOR_DOCTORS_ROUTE,
    FOR_PATIENTS_ROUTE,
    GROUP_CONTACT_ROUTE,
    HIPAA_DISCLAIMER_ROUTE,
    IM_ROUTE,
    INDEX_PAGE_ROUTE,
    INDEX_ROUTE_BY_ROLE,
    PATIENT_ROUTE,
    PATIENTS_ANALYTICS_ROUTE,
    PRICING_ROUTE,
    PRIVACY_POLICY_ROUTE,
    PROFILE_ROUTE,
    PROMO_CODES_ROUTE,
    PROVIDER_LIST_ROUTE,
    RESET_PASSWORD_ROUTE,
    SET_PASSWORD_ROUTE,
    SIGN_IN_ROUTE,
    SIGN_UP_ROUTE,
    SSI_QUESTIONNAIRE_DETAILS_ROUTE,
    SSI_QUESTIONNAIRE_LIST_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
} from 'constants/routes';
import { graphql, Query } from 'react-apollo';
import {
    ConnectedRouter as Router,
    push,
    replace,
} from 'connected-react-router';
import { history } from './utils/configureStore';
import pathOr from 'ramda/es/pathOr';
import styled, { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'constants/styled';
import { updateUserParamsAction, userLogoutAction } from 'reducers/user';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import AppToastContainer from './components/ToastContainer';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { putUnreadMessagesCountToStoreAction } from './reducers/messaging';
import { messageFromError, UNREAD_MESSAGES_INTERVAL_MS } from './constants/ui';
import { toast } from 'react-toastify';
import {
    ADMIN_QUERY,
    CLINIC_QUERY,
    DOCTOR_QUERY,
    PATIENT_QUERY,
    UNIVERSAL_USER_QUERY,
} from './constants/gql/me';
import { UNREAD_CHAT_MESSAGES } from './constants/gql/messaging';
import { isMobile } from 'react-device-detect';
import AppCookiesConsent from './components/CookiesConsent/Desktop';
import AppCookiesConsentMobile from './components/CookiesConsent/Mobile';
import { GET_USER_DATA_BY_TOKEN } from './constants/gql/gql';
import LoadingPageTemplate from './components/PageTemplates/LoadingPageTemplate';
import { LOADING_PAGE_TITLE_MSG } from './constants/localization';
import { LOADING_FROM_TOKEN_ANALYTIC_PAGE } from './constants/analytics/pageNames';

const Index = lazy(() => import('pages/Index'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));

const LnGroupContact = lazy(() => import('pages/Landing/LnGroupContact'));
const HipaaDisclaimer = lazy(() => import('pages/HipaaDisclaimer'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'));

const PrivateRoutes = lazy(() => import('./PrivateRoutes'));

const GlobalStyle = createGlobalStyle`${globalStyles}`;

const AppWrapper = styled.div`
    display: flex;
    flex-grow: 1;
`;

const FallbackWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const UnreadMessagesPolling = compose<{}, { user: UserType }>(
    branch(({ user }) => !user, renderNothing),
    connect(
        (state: any) => ({
            unreadCount: state.messaging.totalUnread,
        }),
        (dispatch) => ({
            updateUnreadMessagesCount: (count: number) =>
                dispatch(putUnreadMessagesCountToStoreAction(count)),
        }),
    ),
    graphql(UNREAD_CHAT_MESSAGES, {
        options: {
            fetchPolicy: 'cache-and-network',
            pollInterval: UNREAD_MESSAGES_INTERVAL_MS,
        },
        props: ({ data }) => ({
            unreadCount: pathOr(0, ['unreadMessagesCount'], data),
        }),
    }),
    lifecycle<any, {}>({
        componentDidUpdate(prevProps) {
            const { updateUnreadMessagesCount, unreadCount } = this.props;

            if (prevProps.unreadCount !== unreadCount) {
                updateUnreadMessagesCount(unreadCount);
            }
        },
    }),
)(() => <></>);

const privateRoutes: string[] = [
    ADMIN_CLINICS_ROUTE,
    ADMIN_DOCTORS_ROUTE,
    ADMIN_MEDICINES_ROUTE,
    ADMIN_PATIENT_ROUTE,
    ADMIN_PROCEDURES_ROUTE,
    BLADDER_DIARY_ROUTE,
    CHOOSING_PLAN_ROUTE,
    CLINIC_DOCTORS_ROUTE,
    CLINIC_PATIENTS_ROUTE,
    DIARY_ROUTE,
    DOCTOR_CLINIC_ROUTE,
    DOCTOR_ROUTE,
    EDIT_CLINIC_PATIENT_BY_ID_ROUTER,
    IM_ROUTE,
    PATIENT_ROUTE,
    PROFILE_ROUTE,
    PROVIDER_LIST_ROUTE,
    RESET_PASSWORD_ROUTE,
    SET_PASSWORD_ROUTE,
    SIGN_IN_ROUTE,
    SIGN_UP_ROUTE,
    SSI_QUESTIONNAIRE_LIST_ROUTE,
    SSI_QUESTIONNAIRE_DETAILS_ROUTE,
    PROMO_CODES_ROUTE,
    `${PATIENT_ROUTE}/:patientID`,
    `${DOCTOR_ROUTE}/:doctorID`,
    `${SIGN_UP_ROUTE}/:type`,
    `${RESET_PASSWORD_ROUTE}/:rt`,
    `${IM_ROUTE}/:role/:userId`,
    `${IM_ROUTE}/:chatId`,
    PATIENTS_ANALYTICS_ROUTE,
    ADMIN_PLANS_ROUTE,
    ALGORITHM_COEFFICIENTS_ROUTE_MH,
    ALGORITHM_COEFFICIENTS_ROUTE_SR
];

const App: React.FunctionComponent<IInternalProps & IExternalProps> = (
    props,
) => (
    <AppWrapper>
        <UnreadMessagesPolling user={props.user} />
        <GlobalStyle />
        <Router history={history}>
            <Suspense
                fallback={
                    <FallbackWrapper>
                        <Loading />
                    </FallbackWrapper>
                }
            >
                <Switch>
                    <Route
                        path={privateRoutes}
                        component={() => <PrivateRoutes {...props} />}
                    />
                    <Route path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
                    <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
                    <Route path={HIPAA_DISCLAIMER_ROUTE} component={HipaaDisclaimer} />
                    <Route
                        path={TERMS_AND_CONDITIONS_ROUTE}
                        component={TermsAndConditions}
                    />

                    <Route
                        path={INDEX_PAGE_ROUTE}
                        exact
                        component={
                            !props.token
                                ? () => {
                                    window.location.replace(SIGN_IN_ROUTE);
                                    return <></>;
                                }
                                : props.role === 'ADMIN' || props.role === 'CLINIC'
                                    ? () => <Redirect to={INDEX_ROUTE_BY_ROLE(props.role)} />
                                    : () => <Index {...props} />
                        }
                    />

                    <Route
                        path={CONTACT_ROUTE}
                        exact
                        component={() => {
                            window.location.replace(
                                `${process.env.MENHEALTH_PUBLIC_URI}${CONTACT_ROUTE}`,
                            );
                            return <></>;
                        }}
                    />

                    <Route
                        path={ABOUT_ROUTE}
                        exact
                        component={() => {
                            window.location.replace(
                                `${process.env.MENHEALTH_PUBLIC_URI}${ABOUT_ROUTE}`,
                            );
                            return <></>;
                        }}
                    />

                    <Route
                        path={FOR_DOCTORS_ROUTE}
                        exact
                        component={() => {
                            window.location.replace(
                                `${process.env.MENHEALTH_PUBLIC_URI}${FOR_DOCTORS_ROUTE}`,
                            );
                            return <></>;
                        }}
                    />

                    <Route
                        path={FOR_PATIENTS_ROUTE}
                        exact
                        component={() => {
                            window.location.replace(
                                `${process.env.MENHEALTH_PUBLIC_URI}${FOR_PATIENTS_ROUTE}`,
                            );
                            return <></>;
                        }}
                    />

                    <Route
                        path={PRICING_ROUTE}
                        exact
                        component={() => {
                            window.location.replace(
                                `${process.env.MENHEALTH_PUBLIC_URI}${PRICING_ROUTE}`,
                            );
                            return <></>;
                        }}
                    />
                    <Route path={GROUP_CONTACT_ROUTE} component={LnGroupContact} />
                    <Route
                        path="*"
                        render={() => {
                            window.location.replace(`${process.env.MENHEALTH_PUBLIC_URI}${SIGN_IN_ROUTE}`);
                            return <></>
                        }}
                    />
                </Switch>
            </Suspense>
            <AppToastContainer />
            {isMobile ? <AppCookiesConsentMobile /> : <AppCookiesConsent />}
        </Router>
    </AppWrapper>
);

const setQuery = (role: UserRoleType) => {
    switch (role) {
        case 'PATIENT':
            return PATIENT_QUERY;
        case 'DOCTOR':
            return DOCTOR_QUERY;
        case 'CLINIC':
            return CLINIC_QUERY;
        case 'ADMIN':
            return ADMIN_QUERY;
        default:
            return UNIVERSAL_USER_QUERY;
    }
};

export default connect()((props: IExternalProps) => {
    const location = window.location;
    const query = new URLSearchParams(location.search);
    const queryToken = query.get('token');
    const { dispatch } = props;

    if (queryToken && !props.token) {
        localStorage.setItem('token', queryToken);
    }

    if (
        (queryToken && (!props.token || !props.role)) ||
        (props.token && queryToken && queryToken !== props.token)
    ) {
        query.delete('token');
        dispatch(replace(`${location.pathname}?${query.toString()}`));
        return (
            <Query query={GET_USER_DATA_BY_TOKEN} variables={{ token: queryToken }}>
                {(queryProps: any) => {
                    const query = new URLSearchParams(location.search);
                    if (queryProps.error && !queryProps.loading) {
                        toast(messageFromError(queryProps.error), { type: 'error' });
                        dispatch(push(`${location.pathname}?${query.toString()}`));
                    }
                    if (queryProps.data && !queryProps.loading) {
                        dispatch(
                            updateUserParamsAction({
                                token: queryToken,
                                role: queryProps.data.getUserDataByToken.role,
                                email: queryProps.data.getUserDataByToken.email,
                                signed: queryProps.data.getUserDataByToken.signed,
                            }),
                        );
                    }
                    return (
                        <LoadingPageTemplate
                            title={LOADING_PAGE_TITLE_MSG}
                            analyticTitle={LOADING_FROM_TOKEN_ANALYTIC_PAGE}
                            centered={true}
                        />
                    );
                }}
            </Query>
        );
    }
    return (
        <Query query={setQuery(props.role)} skip={!props.token || !props.role}>
            {(queryProps: any) => {
                if (location.pathname?.includes('/plans')) {
                    if (!props.token || !queryToken) {
                        window.location.replace(`${process.env.MENHEALTH_PUBLIC_URI}${SIGN_IN_ROUTE}`);
                    }
                    return null;
                }

                if (
                    queryProps.error &&
                    props.signed &&
                    CHOOSING_PLAN_ROUTE !== location.pathname
                ) {
                    toast(messageFromError(queryProps.error), { type: 'error' });
                }
                const { token } = props;
                //TODO: query partially duplicates getUserAccess method. Refactoring required to un
                const user: UserType = pathOr(null, ['data', 'profile'], queryProps);
                const medicines = pathOr([], ['data', 'medicines'], queryProps);
                const procedures = pathOr([], ['data', 'procedures'], queryProps);

                const stayPageRoutes = [
                    PRIVACY_POLICY_ROUTE,
                    HIPAA_DISCLAIMER_ROUTE,
                    TERMS_AND_CONDITIONS_ROUTE,
                ];
                if (
                    !user &&
                    token &&
                    !queryProps.loading &&
                    !stayPageRoutes.includes(history.location.pathname)
                ) {
                    if (
                        ![CHOOSING_PLAN_ROUTE, SIGN_IN_ROUTE].includes(location.pathname)
                    ) {
                        if (props.signed === false) {
                            dispatch(push(SET_PASSWORD_ROUTE));
                        } else if (
                            props.role === 'PATIENT' ||
                            props.role === 'DOCTOR' ||
                            props.role === 'CLINIC'
                        ) {
                            dispatch(push(CHOOSING_PLAN_ROUTE));
                        } else {
                            dispatch(push(SIGN_IN_ROUTE));
                        }
                    }
                }

                if (
                    user &&
                    token &&
                    !queryProps.loading &&
                    !user.isActive &&
                    user.role !== 'ADMIN'
                ) {
                    dispatch(push(CHOOSING_PLAN_ROUTE));
                }

                if (user && token) {
                    if (
                        !user.active &&
                        (user.role === 'DOCTOR' || user.role === 'PATIENT')
                    ) {
                        dispatch(userLogoutAction());
                    }
                }

                if (pathOr(false, ['loading'], queryProps)) {
                    return (
                        <LoadingPageTemplate
                            title={LOADING_PAGE_TITLE_MSG}
                            analyticTitle={LOADING_FROM_TOKEN_ANALYTIC_PAGE}
                            centered={true}
                        />
                    );
                }

                return (
                    <App
                        {...props}
                        user={user}
                        loading={queryProps.loading}
                        availMedicines={medicines}
                        availProcedures={procedures}
                    />
                );
            }}
        </Query>
    );
});

export interface IExternalProps {
    token: string;
    role: UserRoleType | null;
    signed: boolean | null;
    email: string | null;
    dispatch: (action: { type: string; payload?: any }) => void;
    platformClient?: string;
}

export interface IInternalProps {
    user: UserType;
    loading: boolean;
    availMedicines: MedicineType[];
    availProcedures: ProcedureType[];
}
