import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { compose, withProps, withState } from 'recompose';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  SIGN_IN_ROUTE,
  FOR_DOCTORS_ROUTE,
  FOR_PATIENTS_ROUTE,
  CONTACT_ROUTE,
  INDEX_PAGE_ROUTE,
  ABOUT_ROUTE,
  PRICING_ROUTE,
  SIGN_UP_ROUTE,
} from 'constants/routes';
import LogoImage from 'assets/logo.png';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import styles from './landing.module.css';
import { SIGN_IN_MSG, SIGN_UP_MSG } from '../../../constants/localization';
import MobileFooter from '../MobileFooter';

export interface Link {
  label: string;
  to: string;
  className?: string;
  isExternal?: boolean;
  isBlank?: boolean;
}

export const links: Link[] = [
  {
    label: 'Home',
    to: INDEX_PAGE_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: 'For Patients',
    to: FOR_PATIENTS_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: 'For Doctors',
    to: FOR_DOCTORS_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: 'About',
    to: ABOUT_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: 'Plans',
    to: PRICING_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: 'Contact',
    to: CONTACT_ROUTE,
    isExternal: true,
    isBlank: false,
  },
  {
    label: SIGN_UP_MSG,
    to: SIGN_UP_ROUTE,
    className: styles.actionLink,
    isExternal: true,
    isBlank: false,
  },
  {
    label: SIGN_IN_MSG,
    to: SIGN_IN_ROUTE,
    className: styles.actionLink,
    isExternal: true,
    isBlank: false,
  },
];

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: ${({ pageType }) =>
    pageType === 'main' ? '450px' : isMobileOnly ? '50px' : '70px'};
  position: ${({ position }) => position};
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    ${(props) => props.theme.colors.pictonGreen},
    ${(props) => props.theme.colors.pictonDarkBlue}
  );
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  z-index: 2;
  padding-right: ${isMobile ? 0 : '20px'};

  @media (max-width: 1150px) {
    padding-right: 0;
  }
`;

const PageLink = styled(NavLink)`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-family: ${(props) => props.theme.fonts.medium};
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;
  padding: 0 20px;
  max-height: 70px;

  &.selected {
    background-color: ${isMobile ? 'inherit' : 'rgba(2, 134, 228, 0.4)'};
  }

  @media (max-width: 1150px) {
    &.selected {
      background-color: inherit;
    }
  }
`;

const ExternalLink = styled.a<{ selected: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-family: ${(props) => props.theme.fonts.medium};
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;
  padding: 0 20px;
  max-height: 70px;

  ${(props) =>
    props.selected
      ? `background-color: ${isMobile ? 'inherit' : 'rgba(2, 134, 228, 0.4)'};`
      : ''};
`;

const Logo = styled.img`
  position: absolute;
  top: ${(props: any) =>
    props.pagetype === 'main' ? '24px' : isMobileOnly ? '15px' : '20px'};
  bottom: auto;
  right: auto;
  left: ${isMobileOnly ? '20px' : isTablet ? '40px' : '60px'};
  margin: auto;
  height: ${isMobileOnly ? '20px' : '26px'};

  @media (max-width: 1336px) {
    top: 24px;
    left: 20px;
    height: 20px;
  }
`;

const PageTitle: any = styled.div`
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  display: ${(props: any) => (props.pagetype === 'main' ? 'flex' : 'none')};
  justify-content: center;
  margin-bottom: 30px;
  user-select: none;
`;

const Strong = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
`;

const TitleWrapper = styled.h1`
  font-size: 50px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.03em;
  font-family: ${(props) => props.theme.fonts.regular};
  color: white;
`;

const MobileMenuWrapper = styled.div`
  display: ${isMobile ? 'block' : 'none'};
  svg {
    * {
      fill: white;
    }
  }
  @media (max-width: 1240px) {
    display: block;
  }
`;

const MobileMenu = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  background: ${({ theme }) => theme.colors.regent};
  transition: 0.5s ease;
  transform: ${({ isOpen }) =>
    isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
  font-family: ${(props) => props.theme.fonts.regular};
`;

const BurgerButton = styled.div`
  position: fixed;
  right: 20px;
  top: ${isMobileOnly ? '27px' : '35px'};
  transform: translateY(-50%);

  @media (max-width: 1150px) {
    top: 35px;
  }
`;

const MobileMenuContent = styled.div`
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const PageLinkMobileWrapper = styled.span`
  display: block;
  margin-bottom: 10px;
`;

const CloseMenuIcon = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;
`;

export const DesktopLinks = styled.div`
  display: ${isMobile ? 'none' : 'inherit'};

  @media (max-width: 1240px) {
    display: none;
  }
`;

const OpenedMobileMenuGlobalStyles = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

const Header: React.FunctionComponent<IProps> = ({
  pageType,
  position = 'relative',
  isMenuOpen,
  setMenuOpen,
  currentRoute,
}) => (
  <Wrapper pageType={pageType} position={position}>
    {isMenuOpen && <OpenedMobileMenuGlobalStyles />}
    <NavLink to={INDEX_PAGE_ROUTE}>
      <Logo src={LogoImage} alt="logo" />
    </NavLink>
    <DesktopLinks>{getDesktopNavLinks(links, currentRoute)}</DesktopLinks>
    <MobileMenuWrapper>
      <BurgerButton onClick={() => setMenuOpen(true)}>
        <MenuIcon width="25px" />
      </BurgerButton>
      <MobileMenu isOpen={isMenuOpen}>
        <CloseMenuIcon onClick={() => setMenuOpen(false)}>close</CloseMenuIcon>
        <MobileMenuContent>
          {getMobileNavLinks(links, currentRoute)}
        </MobileMenuContent>
        <MobileFooter />
      </MobileMenu>
    </MobileMenuWrapper>
    <PageTitle pagetype={pageType}>
      <TitleWrapper>
        <Strong>MyUroflow</Strong> - Taking Urinary Health Care
        <br />
        to the Next Level
      </TitleWrapper>
    </PageTitle>
  </Wrapper>
);

export default compose<IInnerProps, IOuterProps>(
  withRouter,
  withProps(({ location }: RouteComponentProps) => ({
    currentRoute: location.pathname,
  })),
  withState('isMenuOpen', 'setMenuOpen', false),
  withProps(({ location }) => ({
    pageType: 'secondary',
  })),
)(Header);

const getDesktopNavLinks = (
  links: Link[],
  currentRoute: string,
): JSX.Element[] =>
  links.map((link: Link) =>
    link.to.startsWith('http') || link.isExternal ? (
      <ExternalLink
        selected={currentRoute === link.to}
        className={link.className}
        key={link.to}
        href={link.to}
        target={link.isBlank && '_blank'}
        rel={'noopener noreferrer'}
      >
        {link.label}
      </ExternalLink>
    ) : (
      <PageLink
        className={link.className}
        key={link.to}
        to={link.to}
        exact
        activeClassName="selected"
      >
        {link.label}
      </PageLink>
    ),
  );

const getMobileNavLinks = (
  links: Link[],
  currentRoute: string,
): JSX.Element[] =>
  links.map(({ label, to, isExternal, isBlank }: Link) => (
    <PageLinkMobileWrapper key={to}>
      {to.startsWith('http') || isExternal ? (
        <ExternalLink
          selected={currentRoute === to}
          href={to}
          target={isBlank && '_blank'}
          rel={'noopener noreferrer'}
        >
          {label}
        </ExternalLink>
      ) : (
        <PageLink to={to} exact activeClassName="selected">
          {label}
        </PageLink>
      )}
    </PageLinkMobileWrapper>
  ));

type IProps = IInnerProps & IOuterProps;

interface IWrapperProps {
  pageType: 'main' | 'secondary';
  position?: 'absolute' | 'relative';
}

interface IInnerProps extends RouteComponentProps {
  pageType: 'main' | 'secondary';
  isMenuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  currentRoute: string;
}

interface IOuterProps {
  position?: 'absolute' | 'relative';
}
