import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import Banner from 'pages/Landing/LnIndex/Banner';
import { useAnalytic } from '../../../constants/analytics/analytics';

export const Page = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.mercury};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div<IBodyProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  width: 100%;
  height: 100%;
  flex: 1;
`;

const LandingTemplate: React.FunctionComponent<IProps> = ({
  children,
  seoKeywords,
  seoDescription,
  pageTitle,
  centered = false,
  analyticTitle = '',
  backgroundColor,
}) => {
  useAnalytic(analyticTitle);
  return (
    <Page backgroundColor={backgroundColor}>
      {/*<OpenGraph title={pageTitle} description={seoDescription} />*/}
      {/*<TwitterCard title={pageTitle} description={seoDescription} />*/}
      {/*<PageTitle title={pageTitle} />*/}
      {/*<Seo keywords={seoKeywords} description={seoDescription}/>*/}
      <Header />
      <Body centered={centered}>{children}</Body>
      <Banner />
      <Footer />
    </Page>
  );
};

export default LandingTemplate;

interface IProps {
  centered?: boolean;
  backgroundColor?: string;
  analyticTitle: string;
  pageTitle: string;
  seoKeywords: string;
  seoDescription: string;
}

interface IBodyProps {
  centered?: boolean;
}
