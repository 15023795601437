import { ActionType } from 'constants/types';
import { merge } from 'ramda';
import { isMobile } from 'react-device-detect';

const TOGGLE_DRAWER_ACTION = 'TOGGLE_DRAWER_ACTION';
const TOGGLE_PATIENT_DRAWER_ACTION = 'TOGGLE_PATIENT_DRAWER_ACTION';
const PATIENTS_SORT_TYPE_ON_CHANGE_ACTION =
  'PATIENTS_SORT_TYPE_ON_CHANGE_ACTION';
const PATIENTS_SORT_DIRECTION_CHANGE_ACTION =
  'PATIENTS_SORT_DIRECTION_CHANGE_ACTION';
const MODAL_MOBILE_MENU_IS_OPEN = 'MODAL_MOBILE_MENU_IS_OPEN';

interface IPatientsSortTypeOnChangeActionPayload {
  value: string;
  patientsSortDirection: string;
}

export const toggleDrawerAction = (isOpen: boolean) => ({
  type: TOGGLE_DRAWER_ACTION,
  payload: { isOpen },
});

export const togglePatientDrawerAction = (isOpen: boolean) => ({
  type: TOGGLE_PATIENT_DRAWER_ACTION,
  payload: { isOpen },
});

export const patientsSortTypeOnChangeAction = ({
  value,
  patientsSortDirection,
}: IPatientsSortTypeOnChangeActionPayload) => ({
  type: PATIENTS_SORT_TYPE_ON_CHANGE_ACTION,
  payload: { patientsSortType: value, patientsSortDirection },
});

export const patientsSortDirectionChangeAction = () => ({
  type: PATIENTS_SORT_DIRECTION_CHANGE_ACTION,
});

export const toggleModalMobileButtons = (isOpen: boolean) => ({
  type: MODAL_MOBILE_MENU_IS_OPEN,
  payload: { isOpen },
});

const initialState: IAppState = {
  drawerIsOpen: !isMobile,
  patientDrawerIsOpen: true,
  patientsSortType: 'lastName_',
  patientsSortDirection: 'ASC',
  isModalMobileButtonsOpen: false,
};

export const appReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case TOGGLE_DRAWER_ACTION:
      return merge(state, {
        drawerIsOpen: action.payload.isOpen,
        patientDrawerIsOpen: action.payload.isOpen && false,
      });
    case TOGGLE_PATIENT_DRAWER_ACTION:
      return merge(state, {
        patientDrawerIsOpen: action.payload.isOpen,
        drawerIsOpen: action.payload.isOpen && false,
      });
    case PATIENTS_SORT_TYPE_ON_CHANGE_ACTION:
      return merge(state, {
        patientsSortType: action.payload.patientsSortType,
        patientsSortDirection: action.payload.patientsSortDirection,
      });
    case PATIENTS_SORT_DIRECTION_CHANGE_ACTION:
      return merge(
        state,
        state.patientsSortDirection === 'DESC'
          ? { patientsSortDirection: 'ASC' }
          : { patientsSortDirection: 'DESC' },
      );
    case MODAL_MOBILE_MENU_IS_OPEN:
      return merge(state, { isModalMobileButtonsOpen: action.payload.isOpen });
    default:
      return state;
  }
};

export interface IAppState {
  drawerIsOpen: boolean;
  patientDrawerIsOpen: boolean;
  patientsSortType: string;
  patientsSortDirection: string;
  isModalMobileButtonsOpen: boolean;
}
