import { ThemeType } from 'constants/types';
import React from 'react';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { toast, ToastContent } from 'react-toastify';
import ButtonLoader from './ButtonLoader';
import { messageFromError } from '../constants/ui';

export const Btn = styled('button')`
  display: flex;
  height: ${isMobileOnly ? '38px' : '40px'};
  font-size: ${isMobileOnly ? '14px' : '16px'};
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding-top: 3px;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: ${(props) => props.theme.fonts.medium};

  &:active:enabled {
    -webkit-box-shadow: inset 0px 0px 6px
      ${(props) => props.theme.colors.pictonSky};
    -moz-box-shadow: inset 0px 0px 6px
      ${(props) => props.theme.colors.pictonSky};
    box-shadow: inset 0px 0px 6px
      ${(props) => props.theme.colors.pictonSky};
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

class Button extends React.Component<IProps> {
  state: IState = {
    disabled: this.props.disabled,
    title: this.props.children,
    onClick: this.onClick.bind(this),
  };

  onClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<any> | any {
    if (this.props.onClick) {
      const clickResult = this.props.onClick(event);
      if (clickResult instanceof Promise && Promise.resolve(clickResult)) {
        // Disable button
        this.setState({
          disabled: true,
          title: <ButtonLoader>{this.props.children}</ButtonLoader>,
        });
        // Un-disable button after promise has resolved or rejected
        return clickResult
          .then((_) => {
            if (this.props.successMessageContent) {
              toast(this.props.successMessageContent, { type: 'info' });
            }
            return _;
          })
          .catch((error: Error) =>
            toast(messageFromError(error), { type: 'error' }),
          )
          .finally(() =>
            this.setState({
              disabled: this.props.disabled,
              title: this.props.children,
            }),
          );
      }
    }
  }

  render() {
    const attr = Object.assign({}, this.props, {
      disabled: this.state.disabled,
      onClick: this.state.onClick,
    });
    return <Btn {...attr}>{this.state.title}</Btn>;
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    if (this.props.children !== prevProps.children) {
      this.setState({ title: this.props.children });
    }
  }
}

export default Button;

interface IState {
  disabled: boolean;
  title: React.ReactNode;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<any> | any;
}

interface IProps {
  children: React.ReactNode;
  disabled?: boolean;
  id?: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void | Promise<any>;
  type?: 'button' | 'submit' | 'reset';
  successMessageContent?: ToastContent;
}

interface IBtnProps {
  theme: ThemeType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'button' | 'submit' | 'reset';
}
