import { PlanType, UserRoleType } from './types';

export const INDEX_PAGE_ROUTE = '/';
export const SIGN_IN_ROUTE = '/signin';
export const SIGN_UP_ROUTE = '/signup';
export const SIGN_UP_ROUTE_PATIENT = '/signup/patient';
export const SIGN_UP_ROUTE_DOCTOR = '/signup/doctor';
export const DIARY_ROUTE = '/test-diary';
export const BLADDER_DIARY_ROUTE = '/bladder-diary';
export const PROVIDER_LIST_ROUTE = '/provider-list';
export const PROFILE_ROUTE = '/profile';
export const PATIENT_ROUTE = '/patient';
export const DOCTOR_CLINIC_ROUTE = '/doctor/clinic';
export const ADMIN_CLINICS_ROUTE = '/admin/clinics';
export const ADMIN_PATIENT_ROUTE = '/admin/patients';
export const RESET_PASSWORD_ROUTE = '/reset';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const HIPAA_DISCLAIMER_ROUTE = '/hipaa-disclaimer';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const CLINIC_DOCTORS_ROUTE = '/clinic/doctors';
export const CLINIC_PATIENTS_ROUTE = '/clinic/patients';
export const DOCTOR_PATIENTS_ROUTE = '/doctor/patients';
export const CLINIC_IMPORT_PATIENTS_ROUTE = '/clinic/patients/import';
export const DOCTOR_IMPORT_PATIENTS_ROUTE = '/doctor/patients/import';
export const CLINIC_SEND_PATIENTS_INVITE_ROUTE =
  '/clinic/patients/import/invites';
export const EDIT_CLINIC_PATIENT_BY_ID_ROUTER =
  '/clinic/patient/edit/:patientID';
export const FOR_DOCTORS_ROUTE = '/for-physicians/';
export const FOR_PATIENTS_ROUTE = '/for-patients/';
export const ABOUT_ROUTE = '/about/';
export const CONTACT_ROUTE = '/contact-information/';
export const GROUP_CONTACT_ROUTE = '/group-contact/';
export const ADMIN_DOCTORS_ROUTE = '/admin/doctors';
export const DOCTORS_ROUTE = '/doctors';
export const SET_PASSWORD_ROUTE = '/set-password';
export const DOCTOR_ROUTE = '/doctor';
export const IM_ROUTE = '/im';
export const ADMIN_MEDICINES_ROUTE = '/admin/medicines';
export const ADMIN_PROCEDURES_ROUTE = '/admin/procedures';
export const ALGORITHM_COEFFICIENTS_ROUTE_MH = '/admin/algorithm-coefficients-menhealth';
export const ALGORITHM_COEFFICIENTS_ROUTE_SR = '/admin/algorithm-coefficients-sunrise';
export const SSI_QUESTIONNAIRE_DETAILS_ROUTE = '/ssi';
export const SSI_QUESTIONNAIRE_LIST_ROUTE = '/ssi/results';
export const PRICING_ROUTE = '/pricing/';
export const CHOOSING_PLAN_ROUTE = '/plans';
export const CHOOSING_IMPORT_ROUTE = '/patients/import';
export const PROMO_CODES_ROUTE = '/promo-codes';
export const SCHEDULE_DEMO_LINK =
  'https://calendly.com/menhealth/myuroflow-menhealth-demo';

export const SIGN_UP_WITH_PROMO_CODE = (promoCode: string): string =>
  `${SIGN_UP_ROUTE}/?promoCode=${promoCode}`;
export const CHOOSE_PLAN_ROUTE = (planGroupType: PlanType): string =>
  `${CHOOSING_PLAN_ROUTE}/${planGroupType}`;
export const REDIRECT_INVALID_PATIENT_HISTORY_ROUTE = (role: UserRoleType) =>
  role === 'CLINIC' ? CLINIC_PATIENTS_ROUTE : INDEX_PAGE_ROUTE;
export const IM_CHAT_ROUTE = (id: string, role: string) =>
  `${IM_ROUTE}/${role}/${id}`;
export const IM_CHAT_ROUTE_BY_ID = (chatId: string) => `${IM_ROUTE}/${chatId}`;
export const GET_DOCTOR_SMARTLINK = (shortId: string) =>
  `${process.env.MENHEALTH_APP_HOST_URI}${SIGN_UP_ROUTE}/?d=${shortId}`;
export const GET_CLINIC_SMARTLINK = (shortId: string) =>
  `${process.env.MENHEALTH_APP_HOST_URI}${SIGN_UP_ROUTE}/?c=${shortId}`;
export const INDEX_ROUTE_BY_ROLE = (role: UserRoleType): string => {
  switch (role) {
    case 'ADMIN':
      return ADMIN_CLINICS_ROUTE;
    case 'CLINIC':
      return CLINIC_DOCTORS_ROUTE;
    default:
      return INDEX_PAGE_ROUTE;
  }
};
export const PATIENTS_ANALYTICS_ROUTE = '/patients-analytics';

export const ADMIN_PLANS_ROUTE = '/admin/plans';
