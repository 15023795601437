import { ActionType } from 'constants/types';
import { merge } from 'ramda';
import { UserRoleType } from 'constants/types';

export const USER_LOGOUT_ACTION = 'USER_LOGOUT_ACTION';
export const USER_NOT_AUTHENTICATED_ACTION = 'USER_NOT_AUTHENTICATED_ACTION';
export const UPDATE_USER_PARAMS_ACTION = 'UPDATE_USER_PARAMS_ACTION';
export const UPDATE_USER_SIGNED_PARAM_ACTION =
  'UPDATE_USER_SIGNED_PARAM_ACTION';
export const UPDATE_DOCTOR_PATIENT_SORT_ACTION =
  'UPDATE_DOCTOR_PATIENT_SORT_ACTION';
export const UPDATE_CLINIC_PATIENT_SORT_ACTION =
  'UPDATE_CLINIC_PATIENT_SORT_ACTION';

export const userLogoutAction = () => ({ type: USER_LOGOUT_ACTION });

export const userNotAuthenticatedAction = () => ({
  type: USER_NOT_AUTHENTICATED_ACTION,
});

export const updateUserParamsAction = (params: IUpdateUserParams) => ({
  type: UPDATE_USER_PARAMS_ACTION,
  payload: params,
});

export const updateSignedParamAction = (signed: boolean) => ({
  type: UPDATE_USER_SIGNED_PARAM_ACTION,
  payload: signed,
});

export const updateDoctorPatientSortAction = (sort: string) => ({
  type: UPDATE_DOCTOR_PATIENT_SORT_ACTION,
  payload: sort,
});

export const updateClinicPatientSortAction = (sort: string) => ({
  type: UPDATE_CLINIC_PATIENT_SORT_ACTION,
  payload: sort,
});

const initialState: IUserState = {
  token: null,
  role: null,
  signed: null,
  email: null,
};

export const userReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case UPDATE_USER_PARAMS_ACTION:
      return merge(state, {
        role: action.payload.role,
        token: action.payload.token,
        signed: action.payload.signed,
        email: action.payload.email,
      });
    case UPDATE_CLINIC_PATIENT_SORT_ACTION:
      return merge(state, {
        patientsForClinicSort: action.payload,
      });
    case UPDATE_DOCTOR_PATIENT_SORT_ACTION:
      return merge(state, {
        patientsForDoctorSort: action.payload,
      });
    case UPDATE_USER_SIGNED_PARAM_ACTION:
      return merge(state, { signed: action.payload });
    default:
      return state;
  }
};

export interface IUserState {
  token: string | null;
  role: UserRoleType | null;
  signed: boolean | null;
  email: string | null;
  patientsForDoctorSort: string | null;
  patientsForClinicSort: string | null;
}

export interface IUpdateUserParams {
  token: string;
  role: UserRoleType;
  signed: boolean;
  email: string;
}
