import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  HIPAA_DISCLAIMER_MSG,
  PRIVACY_POLICY_MSG,
  GET_RIGHTS_MSG,
  PATENTS_MSG,
  CONTACT_MAIL_ADDRESS_MSG,
  TERMS_AND_CONDITIONS_MSG,
} from 'constants/localization';
import {
  HIPAA_DISCLAIMER_ROUTE,
  INDEX_PAGE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from 'constants/routes';
import { Link, links } from './Header';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import SocialNetworks from '../SocialNetworks';

const Wrapper = styled.div`
  height: ${isMobile ? 'auto' : '155px'};
  background-color: ${(props) => props.theme.colors.regent};
  justify-content: space-between;
  color: white;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  font-family: ${(props) => props.theme.fonts.regular};
  flex-direction: ${isMobile ? 'column' : 'row'};
  padding: ${isMobileOnly ? '35px 30px' : isTablet ? '35px 40px' : '20px 40px'};
  display: flex;
  position: relative;

  @media (max-width: 1353px) {
    height: auto;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const Lnk = styled(NavLink)`
  color: white;
  text-decoration: none;

  &:hover {
    color: #8ff2fe;
  }
`;

const NavLnk = styled(NavLink)`
  color: white;
  text-decoration: none;
  margin-bottom: 15px;
  display: block;

  &.selected {
    color: #8ff2fe;
  }

  &:hover {
    color: #8ff2fe;
  }
`;

const Links = styled.div`
  display: ${isMobileOnly ? 'flex-reverse' : 'flex'};
  font-size: ${isMobile ? '14px' : '16px'};
  margin-top: 15px;
  justify-content: start;
  > a:first-child {
    margin-right: ${isMobile ? '15px' : '30px'};
  }
  > a:last-child {
    margin-left: ${isMobile ? '15px' : '30px'};
  }
  > a {
    color: #8ff2fe;
  }

  @media (max-width: 1353px) {
    display: flex-reverse;
    justify-content: center;
    font-size: 14px;
  }
`;

const Navigation = styled.div`
  display: flex;
  width: 345px;
  flex-wrap: wrap;
  flex-direction: column;
  height: 120px;
  order: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobile ? 'flex-start' : 'flex-end'};
  font-size: ${isMobile ? '14px' : '16px'};
  order: 3;

  @media (max-width: 1353px) {
    align-items: flex-end;
    font-size: 14px;
  }
`;

const RightsBlock = styled.div`
  margin-bottom: 15px;
  text-align: end;
`;

const Center = styled.div`
  order: 2;
`;

const Footer: React.FunctionComponent = () => (
  <Wrapper>
    <Navigation>{getFooterLinks(links)}</Navigation>
    <Center>
      <SocialNetworks />
      <Links>
        <Lnk target={'_tab'} to={HIPAA_DISCLAIMER_ROUTE}>
          {HIPAA_DISCLAIMER_MSG}
        </Lnk>
        <Lnk target={'_tab'} to={PRIVACY_POLICY_ROUTE}>
          {PRIVACY_POLICY_MSG}
        </Lnk>
        <Lnk target={'_tab'} to={TERMS_AND_CONDITIONS_ROUTE}>
          {TERMS_AND_CONDITIONS_MSG}
        </Lnk>
      </Links>
    </Center>
    <RightContainer>
      <RightsBlock>{GET_RIGHTS_MSG()}</RightsBlock>
      <RightsBlock>{PATENTS_MSG}</RightsBlock>
      <RightsBlock>{CONTACT_MAIL_ADDRESS_MSG}</RightsBlock>
    </RightContainer>
  </Wrapper>
);

const getFooterLinks = (links: Link[]): JSX.Element[] =>
  links.map((link: Link) => {
    //we don't need home/external links at the footer
    if (link.to === INDEX_PAGE_ROUTE || link.to.startsWith('http')) {
      return null;
    }
    return (
      <NavLnk key={link.to} to={link.to} activeClassName="selected">
        {link.label.toUpperCase()}
      </NavLnk>
    );
  });

export default Footer;
